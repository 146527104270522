import styled from '@emotion/styled'
import { Primary700, White } from '@mehilainen/mds-customer/colors'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useInlineLinks } from '../../hooks/useInlineLinks'
import { breakpoint } from '../../utils/breakpoint'
import { maxWidth } from '../../utils/breakpoint'
import { scale } from '../../utils/scale'
import { ColumnFlex, MinMaxDiv, RowFlex } from '../Layout/Layout'
import Logo from '../NavBar/desktopLogoWhite.svg?react'
import { Text } from '../Typography/Typography'

import SocialMediaButtons from './SocialMediaButtons'

declare global {
  interface Window {
    Cookiebot?: {
      show?(): void
    }
  }
}

const Container = styled(MinMaxDiv)`
  background: ${Primary700};
  color: ${White};
  padding: ${scale(4)};

  @media (min-width: ${maxWidth.body + 1}px) {
    width: ${maxWidth.body}px;
    margin: auto;
  }
`
Container.defaultProps = { as: 'footer', size: `${maxWidth.banner}px` }

const CustomerServiceContainer = styled(ColumnFlex)`
  gap: ${scale(1)};
`

const HighlightedLinksContainer = styled(ColumnFlex)`
  gap: ${scale(3)};

  @media (max-width: ${breakpoint.sm}px) {
    flex-direction: column;
    align-items: start;
  }
`

const LinksContainer = styled(RowFlex)`
  gap: ${scale(2)};
  align-items: center;

  @media (max-width: ${breakpoint.md}px) {
    flex-direction: column;
    align-items: start;
  }
`

const Link = styled(Text)`
  color: ${White};
  text-decoration: none;

  &:focus,
  &:hover {
    text-decoration: underline;
  }
`
Link.defaultProps = { className: 'no-link-styles no-external-icon' }

const LogoContainer = styled.div`
  object-fit: contain;
  height: ${scale(4)};
  margin-bottom: ${scale(4)};
`

const CustomerServiceText = styled(Text)`
  text-transform: uppercase;
  margin-bottom: ${scale(1)};
`
CustomerServiceText.defaultProps = { $size: 200, $height: 'Small', $weight: 'Medium' }

const Copyright = styled(Text)``
Copyright.defaultProps = { $size: 300 }

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-row-gap: ${scale(5)};

  @media (max-width: ${breakpoint.xl}px) {
    ${LinksContainer}, ${SocialMediaButtons} {
      grid-column: span 2;
    }
  }

  @media (max-width: ${breakpoint.sm}px) {
    ${HighlightedLinksContainer} {
      grid-column: span 2;
    }
  }
`
const openCookieConsent = () => {
  window?.Cookiebot?.show?.()
}

const Footer: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation()

  const featuredLinks = t('component.footer.featuredLinks').split(';')
  const links = t('component.footer.links').split(';')

  const handleInlineLinks = useInlineLinks()

  return (
    <Container data-cy="footer-container">
      <ContentContainer>
        <CustomerServiceContainer>
          <LogoContainer>
            <Logo />
          </LogoContainer>
          <CustomerServiceText>{t('component.footer.customerService.heading')}</CustomerServiceText>
          <Link as="a" href={t('component.footer.customerService.numberLink')} $size={500}>
            {t('component.footer.customerService.numberText')}
          </Link>
          <Text $size={300} $weight="Regular">
            {t('component.footer.customerService.priceDetails')}
          </Text>
        </CustomerServiceContainer>

        <HighlightedLinksContainer>
          {featuredLinks.map((key) => (
            <Link
              as="a"
              target="_blank"
              href={t(`component.footer.linkContents.${key}.link`)}
              $size={400}
              key={key}
              onClick={handleInlineLinks}
            >
              {t(`component.footer.linkContents.${key}.text`)}
            </Link>
          ))}
        </HighlightedLinksContainer>

        <LinksContainer>
          <Text as="button" onClick={openCookieConsent} $size={200} $weight="Regular">
            {t('component.footer.cookieConsent')}
          </Text>
          {links.map((key) => (
            <Link
              as="a"
              target="_blank"
              href={t(`component.footer.linkContents.${key}.link`)}
              $size={200}
              $weight="Regular"
              key={key}
              onClick={handleInlineLinks}
            >
              {t(`component.footer.linkContents.${key}.text`)}
            </Link>
          ))}
        </LinksContainer>

        <SocialMediaButtons />

        <Copyright>© Mehiläinen</Copyright>
      </ContentContainer>
    </Container>
  )
}

export default Footer
