import styled from '@emotion/styled'
import { Gray100, Gray500, Gray900 } from '@mehilainen/mds-customer/colors'
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import React, { useState, useEffect, useMemo } from 'react'

import { InsuranceCompany, NotificationStyle } from '../../../__generated__/api'
import { InsurancePaymentType } from '../../../state/common/selectors'
import { useInsurances } from '../../hooks/useInsurance'
import useTranslation from '../../hooks/useTranslation'
import * as Analytics from '../../utils/analytics'
import { scale } from '../../utils/scale'
import { ColumnFlex, VisuallyHidden } from '../Layout/Layout'
import ResponsiveModal from '../Modal/ResponsiveModal/ResponsiveModal'
import Notification from '../Notification/Notification'
import { Text } from '../Typography/Typography'

import FenniaLogo from './images/Fennia.svg?react'
import IfLogo from './images/If.svg?react'
import LahiTapiolaLogo from './images/LahiTapiola.svg?react'
import PohjantahtiLogo from './images/Pohjantahti.svg?react'
import PohjolaLogo from './images/Pohjola.svg?react'

const Container = styled(ColumnFlex)`
  gap: ${scale(2)};
`

const Heading = styled(Text)`
  margin-bottom: ${scale(1.5)};
`

const StyledLabel = styled(InputLabel)`
  color: ${Gray900};
  font-size: 0.875rem;
  margin-bottom: ${scale(0.75)};
`

const StyledSelect = styled(Select)`
  .MuiSelect-root {
    background-color: ${Gray100};
  }
  .MuiFormHelperText-root {
    display: none;
  }
  [class*='SelectIconContainer'] {
    border-left: none;
  }
  fieldset {
    border: 1px solid ${Gray500};
  }
`

const ButtonsContainer = styled.div`
  width: 50%;
  margin: ${scale(2.5)} auto auto auto;
  display: flex;
  flex-direction: column;
`

const StyledButton = styled(Button)`
  margin-bottom: ${scale(1.5)};
  height: 56px;
  font-size: 1rem;
  font-weight: 400;

  &:last-of-type {
    margin-bottom: 0;
  }
`

interface InsuranceLogoProps {
  insuranceId: string
}

const InsuranceLogo: React.FC<React.PropsWithChildren<InsuranceLogoProps>> = ({ insuranceId }) => {
  const logoProps = {
    width: '18px',
    height: '18px',
    style: { marginRight: '16px' },
  }

  switch (Number(insuranceId)) {
    case 56:
      return <LahiTapiolaLogo {...logoProps} />
    case 804:
      return <PohjolaLogo {...logoProps} />
    case 38:
      return <FenniaLogo {...logoProps} />
    case 429:
      return <IfLogo {...logoProps} />
    case 527:
      return <PohjantahtiLogo {...logoProps} />
    default:
      return null
  }
}

interface InsuranceInstructionsProps {
  insurance: InsuranceCompany
  insurances: InsuranceCompany[]
}

const InsuranceInstructions: React.FC<React.PropsWithChildren<InsuranceInstructionsProps>> = ({
  insurance,
  insurances,
}) => {
  const { t } = useTranslation()

  if (
    insurance.id === 56 &&
    insurances.some(
      (i) =>
        i.id === 56 &&
        i.insurancePayerOptions.some((option) => option.additionalInfoForm === 'lahitapiola')
    )
  ) {
    return (
      <Notification
        style={NotificationStyle.Info}
        title={t('component.insuranceInstructions.lahiTapiola.heading')}
      >
        {t('component.insuranceInstructions.lahiTapiola.text')}
      </Notification>
    )
  }

  if (
    insurance.id === 804 &&
    insurances.some(
      (i) =>
        i.id === 804 &&
        i.insurancePayerOptions.some((option) => option.additionalInfoForm === 'pohjola')
    )
  ) {
    return (
      <Notification style={NotificationStyle.Info}>
        {t('component.insuranceInstructions.pohjola.text')}
      </Notification>
    )
  }

  if (insurance.bookingInstructions) {
    return (
      <Notification style={NotificationStyle.Info}>{insurance.bookingInstructions}</Notification>
    )
  }

  return null
}

interface InsurancePaymentTypeSelectProps {
  value: InsurancePaymentType
  selectedInsurance: number | 'other' | null
  onChange(optionId: InsurancePaymentType): void
}

const InsurancePaymentTypeSelect: React.FC<
  React.PropsWithChildren<InsurancePaymentTypeSelectProps>
> = ({ value, selectedInsurance, onChange }) => {
  const { t, i18n } = useTranslation()

  return (
    <FormControl>
      <VisuallyHidden>
        <FormLabel id="insurancePayerOptionSelect-label">
          {t('component.insuranceSelectModal.payerOptionSelectLabel')}
        </FormLabel>
      </VisuallyHidden>
      <RadioGroup
        aria-labelledby="insurancePayerOptionSelect-label"
        name="insurancePayerOptionSelect-radioGroup"
        value={value}
        onChange={(event) => onChange(event.target.value as InsurancePaymentType)}
      >
        <FormControlLabel
          value={InsurancePaymentType.SELF}
          control={<Radio color="primary" />}
          label={
            i18n.exists(`component.insuranceSelectModal.paymentType.${selectedInsurance}.self`)
              ? t(`component.insuranceSelectModal.paymentType.${selectedInsurance}.self`)
              : t('component.insuranceSelectModal.paymentType.self')
          }
          data-cy="insurancePaymentTypeSelect-self"
        />
        <FormControlLabel
          value={InsurancePaymentType.INSURANCE_COMPANY}
          control={<Radio color="primary" />}
          label={
            i18n.exists(
              `component.insuranceSelectModal.paymentType.${selectedInsurance}.insuranceCompany`
            )
              ? t(
                  `component.insuranceSelectModal.paymentType.${selectedInsurance}.insuranceCompany`
                )
              : t('component.insuranceSelectModal.paymentType.insuranceCompany')
          }
          data-cy="insurancePaymentTypeSelect-insuranceCompany"
        />
      </RadioGroup>
    </FormControl>
  )
}

interface Props {
  open: boolean
  onSelect: (
    id: number | 'other' | null,
    paymentType: InsurancePaymentType,
    redirect?: boolean
  ) => void
  onCancel: () => void
  selectedInsurance: number | 'other' | null
  preSelectedInsurance?: number | 'other'
}

const InsuranceSelectModal: React.FC<React.PropsWithChildren<Props>> = ({
  open,
  onSelect,
  onCancel,
  selectedInsurance,
  preSelectedInsurance,
}) => {
  const { t } = useTranslation()
  const { insurances } = useInsurances()
  const options = useMemo(
    () => [
      {
        value: 'select',
        label: t('common.select'),
      },
      ...insurances.map((insurance) => ({
        value: insurance.id.toString(),
        label: insurance.name,
      })),
      {
        value: 'other',
        label: t('common.otherInsurance'),
      },
    ],
    [insurances, t]
  )

  const [selectedInsuranceId, setSelectedInsuranceId] = useState<number | 'select' | 'other'>(
    selectedInsurance ?? 'select'
  )
  const selectedInsuranceName = useMemo(
    () => options.find((option) => option.value === selectedInsuranceId)?.label ?? '',
    [options, selectedInsuranceId]
  )
  const isPaymentTypeSelectVisible = typeof selectedInsuranceId === 'number'

  const [selectedInsurancePaymentType, setSelectedInsurancePaymentType] =
    useState<InsurancePaymentType>(InsurancePaymentType.SELF)
  const selectedInsuranceDetails = insurances.find(
    (insurance) => insurance.id === selectedInsuranceId
  )

  useEffect(() => {
    if (preSelectedInsurance !== undefined) {
      setSelectedInsuranceId(preSelectedInsurance)
    }
  }, [preSelectedInsurance])

  useEffect(() => {
    if (selectedInsurance === null) {
      setSelectedInsuranceId('select')
    }
  }, [selectedInsurance, setSelectedInsuranceId])

  useEffect(() => {
    if (selectedInsuranceId === 'other') {
      setSelectedInsurancePaymentType(InsurancePaymentType.SELF)
    }
  }, [selectedInsuranceId, setSelectedInsurancePaymentType])

  return (
    <ResponsiveModal open={open} fullWidth onClose={onCancel}>
      <Container>
        <div>
          <Heading as="h1" $size={500} $weight={'Medium'} $color={Gray900} $height="Medium">
            {t('component.insuranceSelectModal.header')}
          </Heading>
          <Text $color={Gray900} $size={300}>
            {t('component.insuranceSelectModal.description')}
          </Text>
        </div>
        <div>
          <StyledLabel id="insurance-select-label">
            {t('component.insuranceSelectModal.selectLabel')}
          </StyledLabel>
          <StyledSelect
            name="insurance-select"
            aria-labelledby="insurance-select-label"
            labelId="insurance-select-label"
            value={selectedInsuranceId.toString()}
            fullWidth
            style={{ marginBottom: 0 }}
            onChange={(event) => {
              const selectedValue = event.target.value
              const selectedNumberValue = Number(selectedValue)
              setSelectedInsuranceId(
                !isNaN(selectedNumberValue)
                  ? selectedNumberValue
                  : (selectedValue as 'select' | 'other')
              )
            }}
            aria-errormessage="insurance-select-error"
            data-cy="insuranceSelectModal-select"
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <InsuranceLogo insuranceId={option.value} />
                {option.label}
              </MenuItem>
            ))}
          </StyledSelect>
        </div>
        {isPaymentTypeSelectVisible && (
          <InsurancePaymentTypeSelect
            value={selectedInsurancePaymentType}
            onChange={setSelectedInsurancePaymentType}
            selectedInsurance={selectedInsurance}
          />
        )}
        {selectedInsuranceDetails && (
          <InsuranceInstructions insurance={selectedInsuranceDetails} insurances={insurances} />
        )}
        <ButtonsContainer>
          <StyledButton
            onClick={() => {
              Analytics.trackInsuranceCompanySelect(
                selectedInsuranceName,
                selectedInsurancePaymentType === InsurancePaymentType.SELF
                  ? 'patient'
                  : 'insCompany',
                'continue'
              )
              onSelect(selectedInsuranceId as number | 'other' | null, selectedInsurancePaymentType)
            }}
            disabled={selectedInsuranceId === 'select'}
            data-cy="insuranceSelectModal-continue"
          >
            {t('common.continue')}
          </StyledButton>
          <StyledButton
            onClick={() => {
              Analytics.trackInsuranceCompanySelect(
                selectedInsuranceName,
                selectedInsurancePaymentType === InsurancePaymentType.SELF
                  ? 'patient'
                  : 'insCompany',
                'cancel'
              )
              onCancel()
            }}
            variant="outlined"
          >
            {t('component.insuranceSelectModal.back')}
          </StyledButton>
        </ButtonsContainer>
      </Container>
    </ResponsiveModal>
  )
}

export default InsuranceSelectModal
