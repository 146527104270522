import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Gray700, Info100 } from '@mehilainen/mds-customer/colors'
import { FormikProps } from 'formik'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { InsurancePayerOption } from '../../../__generated__/api'
import TextFieldNew from '../../../common/components/InputFieldNew/TextFieldNew'
import { ColumnFlex, RowFlex } from '../../../common/components/Layout/Layout'
import { Text } from '../../../common/components/Typography/Typography'
import { useIsMobile } from '../../../common/hooks/useBreakpoint'
import { useFeatureFlags } from '../../../common/hooks/useFeatureFlags'
import { getSsnAge } from '../../../common/utils/ssn'

import { StyledCheckBox, StyledFormControlLabel } from './FormComponents'
import ReserveFormFieldset from './ReserveFormFieldset'

const PhoneContainer = styled(RowFlex)<{ fullWidth: boolean; variant: 'text' | 'contained' }>`
  justify-content: space-between;
  flex-wrap: wrap;
  & > * {
    width: ${(props) => (props.fullWidth ? '100%' : 'calc(50% - 12px)')};
  }

  ${(props) =>
    props.variant === 'contained' &&
    css`
      padding: 12px;
      border-radius: 4px;
      background: ${Info100};
    `}
`

interface Props
  extends Pick<
    FormikProps<{
      ssn: string
      email: string
      phone: string
      sendAttachments: boolean
      sendConfirmationSms: boolean
      minorDisclosureOfInformation: boolean
    }>,
    'values' | 'handleChange' | 'handleBlur' | 'errors' | 'setFieldValue'
  > {
  isCallbackAppointment: boolean
  hasAttachments?: boolean
  disableMinorDisclosure?: boolean
  insurancePayerOption?: InsurancePayerOption
}

const ReserveContactInformationForm: React.FC<React.PropsWithChildren<Props>> = ({
  values,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  hasAttachments,
  disableMinorDisclosure,
  insurancePayerOption,
  isCallbackAppointment,
}) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const [isMinor, setIsMinor] = useState<boolean>(false)
  const featureFlags = useFeatureFlags()

  useEffect(() => {
    const age = getSsnAge(values.ssn?.trim())
    if (age !== null && age < 18 && age >= 0) {
      setIsMinor(true)
    } else {
      setIsMinor(false)
      setFieldValue('minorDisclosureOfInformation', false)
    }
  }, [values, setFieldValue])

  const showMinorDisclosureOfInformation =
    Boolean(featureFlags?.minorDisclosureOfInformationEnabled) && isMinor && !disableMinorDisclosure

  const showInsurancePayerDisclosureOfInformation = Boolean(
    showMinorDisclosureOfInformation && insurancePayerOption
  )

  const minorDisclosureInformationInsuranceLink = t(
    'component.reserveContactInformationForm.minorDisclosureOfInformationInsuranceLink'
  )

  const minorDisclosureInformationLink = t(
    'component.reserveContactInformationForm.minorDisclosureOfInformationLink'
  )

  return (
    <ReserveFormFieldset legend={t('component.reserveContactInformationForm.subHeading')}>
      <TextFieldNew
        id="email"
        label={t('component.reserveContactInformationForm.label.email')}
        helperText={errors.email}
        error={Boolean(errors.email)}
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        required
        data-cy="emailField"
        style={{ marginBottom: '5px' }}
      />
      {hasAttachments && (
        <StyledFormControlLabel
          addBottomMargin
          control={
            <StyledCheckBox
              disableRipple
              name="sendAttachments"
              checked={values.sendAttachments}
              onChange={handleChange}
              color="primary"
            />
          }
          label={
            <ColumnFlex>
              <Text $size={300}>
                {t('component.reserveContactInformationForm.label.sendAttachments')}
              </Text>
              <Text $size={200} $color={Gray700} as="p">
                {t('component.reserveContactInformationForm.label.sendAttachmentsText')}
              </Text>
            </ColumnFlex>
          }
        />
      )}
      <PhoneContainer
        fullWidth={isMobile || isCallbackAppointment}
        variant={isCallbackAppointment ? 'contained' : 'text'}
      >
        <TextFieldNew
          id="phone"
          label={t('component.reserveContactInformationForm.label.phone')}
          helperText={errors.phone}
          error={Boolean(errors.phone)}
          value={values.phone}
          onChange={handleChange}
          onBlur={handleBlur}
          required
          data-cy="phoneField"
        />
        {isCallbackAppointment && (
          <Text $size={300} $weight="Regular" $height="Medium">
            {t('component.reserveContactInformationForm.callbackInfo')}
          </Text>
        )}
      </PhoneContainer>
      <StyledFormControlLabel
        control={
          <StyledCheckBox
            disableRipple
            name="sendConfirmationSms"
            checked={values.sendConfirmationSms}
            onChange={handleChange}
            color="primary"
          />
        }
        label={<Text $size={300}>{t('component.bookingConfirmed.sendSMS')}</Text>}
      />

      {showMinorDisclosureOfInformation ? (
        <StyledFormControlLabel
          addTopMargin
          control={
            <StyledCheckBox
              disableRipple
              name="minorDisclosureOfInformation"
              checked={values.minorDisclosureOfInformation}
              color="primary"
              onChange={handleChange}
            />
          }
          label={
            <ColumnFlex>
              <Text $size={300}>
                {t('component.reserveContactInformationForm.label.minorDisclosureOfInformation')}
              </Text>
              {showInsurancePayerDisclosureOfInformation ? (
                <div>
                  {values.minorDisclosureOfInformation && (
                    <Text $size={200} $color={Gray700} as="p">
                      {t(
                        'component.reserveContactInformationForm.minorDisclosureOfInformationInsuranceInfo'
                      )}
                    </Text>
                  )}
                  {minorDisclosureInformationInsuranceLink && (
                    <Text
                      $size={200}
                      $weight="Medium"
                      as="a"
                      href={minorDisclosureInformationInsuranceLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t('common.readMore')}
                    </Text>
                  )}
                </div>
              ) : (
                <div>
                  {values.minorDisclosureOfInformation && (
                    <Text $size={200} $color={Gray700} as="p">
                      {t(
                        'component.reserveContactInformationForm.minorDisclosureOfInformationInfo'
                      )}
                    </Text>
                  )}
                  {minorDisclosureInformationLink && (
                    <Text
                      $size={200}
                      $weight="Medium"
                      as="a"
                      href={minorDisclosureInformationLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t('common.readMore')}
                    </Text>
                  )}
                </div>
              )}
            </ColumnFlex>
          }
        />
      ) : null}
    </ReserveFormFieldset>
  )
}

export default ReserveContactInformationForm
