import styled from '@emotion/styled'
import { Gray800, Primary300, White, Gray700 } from '@mehilainen/mds-customer/colors'
import { QuestionCircle, Times } from '@mehilainen/mds-customer/icons'
import Popover from '@mui/material/Popover'
import { default as MUITooltip } from '@mui/material/Tooltip'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'

import { Text } from '../Typography/Typography'

const StyledPopover = styled(Popover)`
  .MuiPopover-paper {
    max-width: 300px;
    color: white;
    background: ${Gray800};
    padding: 16px 8px 8px 8px;
    border-radius: 8px;
  }
`

const StyledText = styled(Text)`
  font-size: 0.75rem;
`

const StyledButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 7px;
`

const StyledClose = styled(StyledButton)`
  position: absolute;
  top: 5px;
  right: 0;
`

interface TooltipProps {
  tooltip?: string
  tooltipLink?: { link: string; text: string }
}

const TooltipBase: React.FC<React.PropsWithChildren<TooltipProps>> = ({ tooltip, tooltipLink }) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const stopClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
  }

  const open = Boolean(anchorEl)

  const id = `tooltip-${uuid()}`

  return (
    <>
      <MUITooltip title={t('common.readMore') ?? ''} aria-label={t('common.readMore')}>
        <StyledButton onClick={handleClick} aria-describedby={id}>
          <QuestionCircle htmlColor={Gray700} />
        </StyledButton>
      </MUITooltip>
      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={stopClick}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <StyledClose onClick={handleClose} aria-label={t('common.close')}>
          <Times htmlColor={White} />
        </StyledClose>
        <StyledText>{tooltip}</StyledText>
        {tooltipLink && (
          <>
            <br />
            <StyledText as="a" $color={Primary300} target="_blank" href={tooltipLink.link}>
              {tooltipLink.text}
            </StyledText>
          </>
        )}
      </StyledPopover>
    </>
  )
}

const Tooltip: React.FC<React.PropsWithChildren<TooltipProps>> = (props) => {
  return props.tooltip ? <TooltipBase {...props} /> : null
}

export default Tooltip
