import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Gray600, Primary500 } from '@mehilainen/mds-customer/colors'
import { Clock } from '@mehilainen/mds-customer/icons'
import { Dayjs } from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useIsMobile } from '../../hooks/useBreakpoint'
import { scale } from '../../utils/scale'
import { formatCallbackTime, formatDate3 } from '../../utils/text'
import { ColumnFlex } from '../Layout/Layout'
import { Text } from '../Typography/Typography'

const TextContainer = styled(ColumnFlex)<Pick<Props, 'variant'>>`
  align-self: center;
  min-width: 80px;
  ${(props) =>
    props.variant !== 'S'
      ? css`
          margin-left: ${scale(1)};
        `
      : null}
  gap: ${(props) => (props.variant === 'S' ? scale(0.5) : scale(1))};
`

const TextWithIcon = styled(Text)`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    color: ${Gray600};
    margin-right: ${scale(0.75)};
  }
`

interface Props {
  date: Dayjs
  duration: number | null
  variant?: 'S' | 'M'
  isCallbackAppointment?: boolean
  className?: string
}

const AppointmentTimeDetails: React.FC<React.PropsWithChildren<Props>> = ({
  date,
  duration,
  variant = 'M',
  isCallbackAppointment,
  className,
}) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const callBackTime = isCallbackAppointment && formatCallbackTime(date)

  return (
    <TextContainer variant={variant} className={className}>
      {isCallbackAppointment ? (
        <>
          {callBackTime ? (
            <>
              <Text
                $size={isMobile ? 200 : 300}
                $weight="Regular"
                $height="Medium"
                $color={Primary500}
                $capitalize
              >
                {formatDate3(date, t)}
              </Text>
              <Text
                $size={isMobile ? 300 : 500}
                $weight="Medium"
                $height="Medium"
                $color={Primary500}
              >
                {callBackTime}
              </Text>
            </>
          ) : (
            <>
              {formatDate3(date, t)
                .split(/(\d{1,2}\.\d{1,2}\.)/g) // Split from possible date
                .map((part, index) =>
                  index === 0 ? (
                    <Text
                      key={`date-part-${index}`}
                      $size={isMobile ? 200 : 300}
                      $weight="Regular"
                      $height="Medium"
                      $color={Primary500}
                      $capitalize
                    >
                      {part}
                    </Text>
                  ) : (
                    <div key={`date-part-${index}`}>
                      <Text
                        $size={isMobile ? 400 : 450}
                        $weight="Medium"
                        $height="Medium"
                        $color={Primary500}
                        $capitalize
                      >
                        {part}
                      </Text>
                    </div>
                  )
                )}
            </>
          )}
        </>
      ) : (
        <>
          <Text
            $size={variant === 'S' ? 300 : 300}
            $weight="Regular"
            $height="Medium"
            $color={Primary500}
            $capitalize
          >
            {formatDate3(date, t)}
          </Text>
          <Text $size={isMobile ? 450 : 500} $weight="Medium" $height="Small" $color={Primary500}>
            {date.format('H:mm')}
          </Text>
          {duration !== null && (
            <TextWithIcon
              $size={variant === 'S' ? 300 : 300}
              $weight="Regular"
              $height="Medium"
              $color={Gray600}
              data-cy={`appointmentTileDetails-${duration}`}
            >
              {variant === 'M' && <Clock />}
              <span>{`${Math.round(duration)} min`}</span>
            </TextWithIcon>
          )}
        </>
      )}
    </TextContainer>
  )
}

export default styled(AppointmentTimeDetails)``
