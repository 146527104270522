import styled from '@emotion/styled'
import { Black } from '@mehilainen/mds-customer/colors'
import { Check } from '@mehilainen/mds-customer/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'

import AnchorButton from '../../../common/components/AnchorButton/AnchorButton'
import Card from '../../../common/components/Card/Card'
import { MinMaxDiv } from '../../../common/components/Layout/Layout'
import OmaMehilainenBanner from '../../../common/components/OmaMehilainenBanner/OmaMehilainenBanner'
import { getCustomerServiceNumber } from '../../../common/services/customerService'
import { getAV3HomeUrl } from '../../../common/utils/url'

const PhoneLink = styled.a`
  margin: 16px 0;
  font-size: 1.625rem;
  color: ${Black};
  text-decoration: none;
`

const StyledButton = styled(AnchorButton)`
  color: white !important;
`

export const AppointmentCancelled: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation()

  return (
    <Card heading={t('component.appointmentCancelled.heading')} icon={<Check />}>
      <MinMaxDiv size="440px" gap="12px" data-cy="appointmentCancelledContainer">
        <OmaMehilainenBanner text={t('component.appointmentCancelled.omaMehilainenBannerText')} />
        <StyledButton size="large" href={getAV3HomeUrl()} showIcon={false}>
          {t('component.appointmentCancelled.link.booking')}
        </StyledButton>
        <a href={t('common.wwwPageLink')}>{t('component.appointmentCancelled.link.frontPage')}</a>
      </MinMaxDiv>
    </Card>
  )
}

export const AppointmentMissing: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t, i18n } = useTranslation()
  const customerServiceNumber = getCustomerServiceNumber('general', i18n.language)

  return (
    <Card heading={t('component.appointmentCancelled.missingHeading')}>
      <MinMaxDiv size="440px" gap="12px" data-cy="appointmentCancelledContainer">
        {t('component.appointmentCancelled.missingText')}
        <PhoneLink href={customerServiceNumber.link}>{customerServiceNumber.label}</PhoneLink>
        <StyledButton size="large" href={getAV3HomeUrl()} showIcon={false}>
          {t('component.appointmentCancelled.link.booking')}
        </StyledButton>
        <a href={t('common.wwwPageLink')}>{t('component.appointmentCancelled.link.frontPage')}</a>
      </MinMaxDiv>
    </Card>
  )
}
