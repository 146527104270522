import styled from '@emotion/styled'
import { Info100, Info800 } from '@mehilainen/mds-customer/colors'

import { scale } from '../../utils/scale'

const BlueNote = styled.div`
  color: ${Info800};
  background: ${Info100};
  border-radius: 4px;
  padding: ${scale(1)} ${scale(1.5)};
  margin-bottom: ${scale(3)};
`

export default BlueNote
