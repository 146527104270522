import { Primary500 } from '@mehilainen/mds-customer/colors'
import { Button } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { SupportedLanguage } from '../../../../__generated__/api'
import { DefaultButton } from '../../../../common/components/DefaultButton/DefaultButton'
import Modal from '../../../../common/components/Modal/Modal'
import api from '../../../../common/services/api'
import { stripTagsAllowBasicHtml } from '../../../../common/utils/xss'
import { ReserveCheckbox } from '../FormComponents'

import {
  ButtonsContainer,
  CheckboxContainer,
  ContentContainer,
  Heading,
  LongText,
  ModalContentsContainer,
  Paragraph,
  StyledExclamationCircle,
} from './Shared'

interface Props {
  insurancesApiInsuranceCompanyId: string
  subjectOmUid: number
  Logo: React.FC<React.PropsWithChildren<unknown>>
  translationKey: string
  onSuccess(): void
  onBack(): void
  onContinueAsSelfPaying(): void
}

const CustomershipCheck: React.FC<React.PropsWithChildren<Props>> = ({
  insurancesApiInsuranceCompanyId,
  subjectOmUid,
  translationKey,
  Logo,
  onSuccess,
  onBack,
  onContinueAsSelfPaying,
}) => {
  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [consent, setConsent] = useState<boolean>(false)
  const [viewState, setViewState] = useState<'default' | 'error' | 'no-customership'>('default')
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const onCheck = useCallback(() => {
    setLoading(true)
    api.v1
      .insuranceCustomership({
        subjectOmUid,
        insuranceCompanyId: insurancesApiInsuranceCompanyId,
        lang: i18n.language as SupportedLanguage,
      })
      .then((res) => {
        setLoading(false)
        if (res.error) {
          setViewState('error')
          return
        }
        if (res.data.hasCustomership) {
          onSuccess()
          return
        }
        setViewState('no-customership')
      })
      .catch(() => {
        setLoading(false)
        setViewState('error')
      })
  }, [i18n.language, onSuccess, subjectOmUid, insurancesApiInsuranceCompanyId])

  const hasModalContents =
    i18n.exists(`component.${translationKey}.customershipCheck.modalContents`) &&
    i18n.exists(`component.${translationKey}.customershipCheck.modalButtonText`)

  if (viewState === 'error') {
    return (
      <ContentContainer>
        <StyledExclamationCircle />
        <Heading>{t(`component.${translationKey}.customershipCheck.error.heading`)}</Heading>
        <Paragraph>
          <Trans
            i18nKey="component.customershipCheck.error.text"
            t={t}
            components={{
              linkTag: <a href={t(`component.${translationKey}.customershipCheck.error.link`)} />,
            }}
          />
        </Paragraph>
        <ButtonsContainer>
          <DefaultButton onClick={onCheck} disabled={!consent || loading}>
            {t(`component.${translationKey}.customershipCheck.error.retry`)}
          </DefaultButton>
          <DefaultButton variant="outlined" onClick={onContinueAsSelfPaying}>
            {t(`component.${translationKey}.customershipCheck.continueAsSelfPaying`)}
          </DefaultButton>
          <DefaultButton variant="text" onClick={onBack}>
            {t('common.goBack')}
          </DefaultButton>
        </ButtonsContainer>
      </ContentContainer>
    )
  }

  if (viewState === 'no-customership') {
    return (
      <ContentContainer>
        <StyledExclamationCircle />
        <Heading>
          {t(`component.${translationKey}.customershipCheck.noCustomership.heading`)}
        </Heading>
        <Paragraph>
          {t(`component.${translationKey}.customershipCheck.noCustomership.text`)}
        </Paragraph>
        <ButtonsContainer>
          <DefaultButton variant="outlined" onClick={onContinueAsSelfPaying}>
            {t(`component.${translationKey}.customershipCheck.continueAsSelfPaying`)}
          </DefaultButton>
          <DefaultButton variant="text" onClick={onBack}>
            {t('common.goBack')}
          </DefaultButton>
        </ButtonsContainer>
      </ContentContainer>
    )
  }

  return (
    <ContentContainer>
      <Logo />
      <Heading>{t(`component.${translationKey}.customershipCheck.heading`)}</Heading>
      <LongText>{t(`component.${translationKey}.customershipCheck.paragraphs`)}</LongText>
      {hasModalContents && (
        <Button onClick={() => setModalOpen(true)} variant="text">
          <Paragraph $color={Primary500}>
            {t(`component.${translationKey}.customershipCheck.modalButtonText`)}
          </Paragraph>
        </Button>
      )}
      <CheckboxContainer>
        <ReserveCheckbox
          name="checkCustomership"
          checked={consent}
          onChange={() => setConsent(!consent)}
          label={t(`component.${translationKey}.customershipCheck.consentCheckboxLabel`)}
        />
      </CheckboxContainer>
      <ButtonsContainer>
        <DefaultButton onClick={onCheck} disabled={loading || !consent}>
          {t(`component.${translationKey}.customershipCheck.checkButtonText`)}
        </DefaultButton>
        <DefaultButton variant="outlined" onClick={onContinueAsSelfPaying}>
          {t(`component.${translationKey}.customershipCheck.continueAsSelfPaying`)}
        </DefaultButton>
        <DefaultButton variant="text" onClick={onBack}>
          {t('common.goBack')}
        </DefaultButton>
      </ButtonsContainer>
      {hasModalContents && (
        <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
          <ModalContentsContainer
            dangerouslySetInnerHTML={{
              __html: stripTagsAllowBasicHtml(
                t(`component.${translationKey}.customershipCheck.modalContents`)
              ),
            }}
          />
        </Modal>
      )}
    </ContentContainer>
  )
}

export default CustomershipCheck
