import styled from '@emotion/styled'
import { Gray100 } from '@mehilainen/mds-customer/colors'

import { ColumnFlex, RowFlex } from '../../../common/components/Layout/Layout'
import Pattern from '../../../common/components/Pattern/Pattern'
import { breakpoint } from '../../../common/utils/breakpoint'
import { scale } from '../../../common/utils/scale'

export const Container = styled(ColumnFlex)`
  min-height: 100%;
`

export const ContentContainer = styled(ColumnFlex)`
  padding: ${scale(3)} 10px;
  align-items: center;
  background: ${Gray100};

  @media (max-width: ${breakpoint.sm}px) {
    padding: ${scale(3)} ${scale(2)};
  }
`

export const NAV_HEIGHT = 60

export const MIN_MAX_WIDTH = '1200px'

export const PatternContainer = styled(ContentContainer)<{ isFromApp: boolean }>`
  ${(props) => !props.isFromApp && `padding-top: ${NAV_HEIGHT}px;`}
  ${Pattern}
`

export const ResponsiveContainer = styled(RowFlex)`
  gap: 16px;

  & > * {
    flex: 1;
  }

  @media (max-width: ${breakpoint.sm}px) {
    flex-direction: column;
  }
`

export const BottomContainer = styled(ContentContainer)`
  flex-grow: 1;
`

export const ResultsContainer = styled.div`
  display: grid;
  grid-template-columns: 300px auto;
  grid-column-gap: ${scale(5)};

  @media (min-width: ${breakpoint.md}px) and (max-width: ${breakpoint.lg}px) {
    grid-column-gap: ${scale(2.5)};
  }

  @media (max-width: ${breakpoint.md}px) {
    display: flex;
    flex-direction: column;
  }
`

export const AppointmentListContainer = styled(ColumnFlex)`
  flex: 4;
`

export const NotificationsContainer = styled(ColumnFlex)`
  gap: ${scale(1.5)};
  margin-bottom: ${scale(2)};
`
