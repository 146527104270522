import styled from '@emotion/styled'
import { Attention400, Gray100, Gray600, White } from '@mehilainen/mds-customer/colors'
import { ExclamationCircle } from '@mehilainen/mds-customer/icons'
import React, { useCallback } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import Avatar, { resourceListImage } from '../../../common/components/Avatar/Avatar'
import { DefaultButton } from '../../../common/components/DefaultButton/DefaultButton'
import {
  CenteredColumnFlex,
  CenteredRowFlex,
  ColumnFlex,
} from '../../../common/components/Layout/Layout'
import NavBar from '../../../common/components/NavBar/NavBar'
import { Text } from '../../../common/components/Typography/Typography'
import useChangeLanguage from '../../../common/hooks/useChangeLanguage'
import useLoginState from '../../../common/hooks/useLoginState'
import { useNode } from '../../../common/hooks/useNode'
import useTranslation from '../../../common/hooks/useTranslation'
import { breakpoint } from '../../../common/utils/breakpoint'
import dayjs from '../../../common/utils/dayjs/dayjs'
import { scale } from '../../../common/utils/scale'
import { formatDateAndTime2 } from '../../../common/utils/text'
import { Gray250 } from '../../../constants'
import {
  generalErrorMessageAtom,
  isFromAppAtom,
  selectedAppointmentNodeIdAtom,
} from '../../../state/common/atoms'
import { selectedAppointmentDetailsAtom } from '../../../state/reserve/atoms'

const Container = styled(ColumnFlex)`
  height: 100%;
  background: ${Gray100};
`

const NavContainer = styled.div`
  background: ${White};
`

const HeadingContainer = styled(ColumnFlex)`
  align-items: center;
  margin-top: 24px;
  gap: 24px;
`

const HeaderContainer = styled(ColumnFlex)`
  gap: 16px;
`

const BodyContainer = styled(CenteredColumnFlex)`
  gap: 24px;
  padding: 0 16px;
`

const StyledExclamationCircle = styled(ExclamationCircle)`
  color: ${Attention400};
`

const AppointmentContainer = styled(CenteredColumnFlex)`
  position: relative;
  overflow: hidden;
  padding: 32px 24px 24px 24px;
  gap: ${scale(2)};
  background: ${Gray250};
  border-radius: 8px;
`

const TextContainer = styled(ColumnFlex)<{ centerMobile?: boolean }>`
  @media (max-width: ${breakpoint.sm}px) {
    text-align: ${({ centerMobile }) => (centerMobile ? 'center' : 'left')};
  }
`

const AppointmentDetailsTitle = styled(Text)`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  padding: 6px 12px 6px 12px;
  color: ${White};
  background: ${Gray600};
  border-radius: 0 0 8px 0;
`

const AppointmentDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 60px;
  padding: 20px;
  opacity: 70%;
  margin: 0 auto;
  @media (max-width: ${breakpoint.sm}px) {
    flex-direction: column;
    gap: 24px;
  }
`

const PractitionerContainer = styled(CenteredRowFlex)`
  gap: 20px;
  align-items: flex-start;
`

interface Props {
  onBackClick: () => void
}

const AppointmentNotFoundView: React.FC<Props> = ({ onBackClick }) => {
  const { t } = useTranslation()
  const isFromApp = useRecoilValue(isFromAppAtom)
  const setGeneralErrorMessage = useSetRecoilState(generalErrorMessageAtom)
  const appointmentInfo = useRecoilValue(selectedAppointmentDetailsAtom)

  const sessionExpiredCallback = useCallback(
    () => setGeneralErrorMessage('common.sessionExpired'),
    [setGeneralErrorMessage]
  )
  const { loginStatus, logout } = useLoginState(sessionExpiredCallback)
  const { changeLanguage } = useChangeLanguage()
  const selectedAppointmentNodeId = useRecoilValue(selectedAppointmentNodeIdAtom)
  const { node, pending: nodePending } = useNode(selectedAppointmentNodeId)

  return (
    <Container>
      {!isFromApp && (
        <NavContainer>
          <NavBar
            onLanguageSelect={changeLanguage}
            loginStatus={loginStatus}
            logoutCallBack={logout}
            variant="detached"
          />
        </NavContainer>
      )}
      <BodyContainer>
        <HeadingContainer>
          <StyledExclamationCircle fontSize="large" />
          <HeaderContainer>
            <Text as="h1" $size={500} $weight="Medium" $height="Medium">
              {t('component.appointmentNotFound.title')}
            </Text>
            <Text as="h2">{t('component.appointmentNotFound.description')}</Text>
          </HeaderContainer>
        </HeadingContainer>
        {appointmentInfo && (
          <AppointmentContainer>
            <AppointmentDetailsTitle $size={300}>
              {t('component.appointmentNotFound.cardTitle')}
            </AppointmentDetailsTitle>
            <AppointmentDetailsContainer>
              <TextContainer centerMobile={true}>
                <Text $size={300} $weight="Medium" $height="Large">
                  {formatDateAndTime2(dayjs(appointmentInfo.time))}
                </Text>
                <Text $size={300} $height="Large">
                  {appointmentInfo.locationName}
                </Text>
                {node && !nodePending && (
                  <Text $size={300} $height="Large">
                    {node.name}
                  </Text>
                )}
              </TextContainer>
              <PractitionerContainer>
                <Avatar
                  width={'50px'}
                  height={'62px'}
                  ratingFontSize="11px"
                  ratingPadding="4px"
                  borderRadius="10px"
                  image={appointmentInfo.isResourceList ? resourceListImage : appointmentInfo.image}
                  tabIndex={0}
                />
                <TextContainer>
                  <Text $size={300} $weight="Medium" $height="Large">
                    {appointmentInfo.specialistName}
                  </Text>
                  <Text $size={300} $height="Large">
                    {appointmentInfo.specialistTitle}
                  </Text>
                </TextContainer>
              </PractitionerContainer>
            </AppointmentDetailsContainer>
          </AppointmentContainer>
        )}
        <DefaultButton onClick={() => onBackClick()}>
          {t('component.appointmentNotFound.backButton')}
        </DefaultButton>
      </BodyContainer>
    </Container>
  )
}

export default AppointmentNotFoundView
