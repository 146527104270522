import styled from '@emotion/styled'
import { White } from '@mehilainen/mds-customer/colors'
import type React from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useResetRecoilState } from 'recoil'

import Modal from '../../../common/components/Modal/Modal'
import { useIsMobile } from '../../../common/hooks/useBreakpoint'
import { filterOptionsOpenAtom } from '../../../state/search/atoms'
import { searchFilterOptionsSelector } from '../../../state/search/selectors'

const StyledModal = styled(Modal)`
  .MuiDialog-paper {
    background: ${White};
  }
`

const SearchFilterModal: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const [modalOpen, setModalOpen] = useRecoilState(filterOptionsOpenAtom)
  const resetFilterOptions = useResetRecoilState(searchFilterOptionsSelector)

  return (
    <StyledModal
      fullWidth
      maxWidth="md"
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      primaryButtonText={t('component.filterOptions.save')}
      secondaryButtonText={t('component.filterOptions.reset')}
      onPrimaryButtonClick={() => setModalOpen(false)}
      onSecondaryButtonClick={() => resetFilterOptions()}
      stickyFooter={isMobile}
    >
      {children}
    </StyledModal>
  )
}

export default SearchFilterModal
