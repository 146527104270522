import styled from '@emotion/styled'
import { Primary500Hex } from '@mehilainen/mds-customer/colors'
import { CircleInfo } from '@mehilainen/mds-customer/icons'
import React from 'react'

import { CenteredRowFlex } from '../Layout/Layout'
import TooltipModal from '../TooltipModal/TooltipModal'

const Container = styled(CenteredRowFlex)<{ iconMargin: string; size: string }>`
  .MuiSvgIcon-root {
    margin-right: ${(props) => props.iconMargin};
    width: ${(props) => props.size};
    height: ${(props) => props.size};
  }
`

const ExternalIcon = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  margin-right: 16px;
`

const StyledButton = styled.button`
  display: flex;

  & {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
  }
`

const isEmbeddedIcon = (icon: JSX.Element | string): icon is JSX.Element => {
  return typeof icon !== 'string'
}

interface Props {
  icon: JSX.Element | string
  tooltip?: string | React.ReactNode
  tooltipLabel?: string
  size?: string
  iconMargin?: string
}

const ComponentWithIcon: React.FC<React.PropsWithChildren<Props>> = ({
  icon,
  children,
  size = '32px',
  iconMargin = '16px',
  tooltip,
  tooltipLabel,
}) => {
  const [open, setOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const onTooltipClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!tooltip) return
    event.stopPropagation()
    setOpen(true)
    setAnchorEl(event.currentTarget)
  }

  return (
    <Container iconMargin={iconMargin} size={size}>
      {tooltip && tooltipLabel ? (
        <StyledButton aria-label={tooltipLabel} onClick={onTooltipClick}>
          <CircleInfo htmlColor={Primary500Hex} />
        </StyledButton>
      ) : isEmbeddedIcon(icon) ? (
        icon
      ) : (
        <ExternalIcon src={icon} size={size} role="presentation" />
      )}
      {children}
      {tooltip && (
        <TooltipModal open={open} onClose={() => setOpen(false)} anchorEl={anchorEl}>
          {tooltip}
        </TooltipModal>
      )}
    </Container>
  )
}

export default ComponentWithIcon
