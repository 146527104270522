import styled from '@emotion/styled'
import { Gray600, Gray900 } from '@mehilainen/mds-customer/colors'
import { TextField } from '@mui/material'

const StyledTextField = styled(TextField)`
  margin-bottom: 16px;

  .MuiInputBase-root {
    padding-right: 14px !important;
    background: white;
  }

  .MuiInputBase-input {
    font-size: 1rem;
    color: ${Gray900};

    &::placeholder {
      opacity: 1;
      color: ${Gray600};
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${Gray600};
  }

  .MuiOutlinedInput-root.Mui-focused {
    outline: auto;
    border: none;
    .MuiOutlinedInput-notchedOutline {
      border: 0;
    }
  }

  .MuiSvgIcon-root {
    color: black;
  }
`

export default StyledTextField
