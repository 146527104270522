import styled from '@emotion/styled'
import { Error400, Primary300 } from '@mehilainen/mds-customer/colors'
import { ExclamationCircle } from '@mehilainen/mds-customer/icons'
import React, { useCallback } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { DefaultButton } from '../../../common/components/DefaultButton/DefaultButton'
import NavBar from '../../../common/components/NavBar/NavBar'
import { Text } from '../../../common/components/Typography/Typography'
import useChangeLanguage from '../../../common/hooks/useChangeLanguage'
import useLoginState from '../../../common/hooks/useLoginState'
import useTranslation from '../../../common/hooks/useTranslation'
import { getCustomerServiceNumber } from '../../../common/services/customerService'
import { generalErrorMessageAtom, isFromAppAtom } from '../../../state/common/atoms'

const Container = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 10px;
`

const HeadingContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  max-width: 800px;
  text-align: center;

  .MuiSvgIcon-root {
    margin: 18px;
    color: ${Error400};
    width: 46px;
    height: 46px;
  }
`

const StyledH1 = styled.h1`
  font-size: 1.625rem;
  text-align: center;
`
const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
`
const StyledH3 = styled.h3`
  text-align: center;
  font-size: 0.875rem;
`
const StyledText = styled(Text)`
  text-align: center;
  font-size: 1.5rem;
  text-decoration: none;
  &:not(.no-link-styles) {
    &:link {
      text-decoration: none;
    }
  }
`
interface Props {
  onBackClick: () => void
}

const CallbackNotAllowedView: React.FC<React.PropsWithChildren<Props>> = ({ onBackClick }) => {
  const { t, i18n } = useTranslation()
  const isFromApp = useRecoilValue(isFromAppAtom)
  const setGeneralErrorMessage = useSetRecoilState(generalErrorMessageAtom)

  const sessionExpiredCallback = useCallback(
    () => setGeneralErrorMessage('common.sessionExpired'),
    [setGeneralErrorMessage]
  )
  const { loginStatus, logout } = useLoginState(sessionExpiredCallback)
  const { changeLanguage } = useChangeLanguage()
  const link = getCustomerServiceNumber('general', i18n.language)
  const { label: telNumber } = link

  return (
    <Container>
      {!isFromApp && (
        <NavBar
          onLanguageSelect={changeLanguage}
          loginStatus={loginStatus}
          logoutCallBack={logout}
          variant="detached"
        />
      )}
      <HeadingContainer>
        <ExclamationCircle />
        <StyledH1>{t('component.callbackNotAllowed.title')}</StyledH1>
        <Text $size={300} $height="Medium" $weight="Regular">
          {t('component.callbackNotAllowed.description')}
        </Text>
      </HeadingContainer>
      <ContactContainer>
        <StyledH3>{t('component.landingModal.contactHeading')}</StyledH3>
        <StyledText as="a" $color={Primary300} target="_blank" href={link.link}>
          {telNumber}
        </StyledText>
        <StyledH3>{t('component.landingModal.customerServicePhonePrice')}</StyledH3>
      </ContactContainer>
      <DefaultButton onClick={() => onBackClick()}>
        {t('component.callbackNotAllowed.backButton')}
      </DefaultButton>
    </Container>
  )
}

export default CallbackNotAllowedView
