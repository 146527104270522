import styled from '@emotion/styled'
import { White } from '@mehilainen/mds-customer/colors'
import { Dialog, DialogProps } from '@mui/material'
import React, { useEffect } from 'react'

import { useIsMobile } from '../../../hooks/useBreakpoint'
import useMobileClose from '../../../hooks/useMobileClose'

export interface ResponsiveDialogProps extends DialogProps {
  omitDefaultPadding?: boolean
  isMobile?: boolean
  disableMobileClose?: boolean
}

const SAFE_SPACE_OFFSET = 20 /* px */
let mouseStartX = 0

const detectClickStart = (event: MouseEvent) => {
  mouseStartX = event.clientX
}

const StyledDialog = styled(Dialog, {
  shouldForwardProp: (prop) =>
    !['omitDefaultPadding', 'isMobile', 'disableMobileClose'].includes(prop),
})<ResponsiveDialogProps>`
  .MuiDialog-paper {
    max-width: 800px;
    padding: ${({ omitDefaultPadding, isMobile }) =>
      omitDefaultPadding ? '0px' : isMobile ? '24px' : '56px'};
    border-radius: ${({ isMobile }) => (isMobile ? '0px' : '16px')};
    background: ${White};
  }
`

const ResponsiveModal: React.FC<React.PropsWithChildren<ResponsiveDialogProps>> = (props) => {
  const isMobile = useIsMobile()

  useMobileClose({
    enabled: !Boolean(props.disableMobileClose),
    onClose: () => props.onClose?.({}, 'backdropClick'),
    open: props.open,
  })

  useEffect(() => {
    window?.addEventListener('mousedown', detectClickStart)
    return () => window?.removeEventListener('mousedown', detectClickStart)
  }, [])

  return props.open ? (
    <StyledDialog
      {...props}
      isMobile={isMobile}
      fullScreen={isMobile}
      scroll="body"
      onClose={(event: MouseEvent, reason) => {
        // If the user has scrolled using scrollbars (implicitly), do not close
        // @see https://github.com/mui/material-ui/issues/23887
        if (
          !props.isMobile &&
          props.onClose &&
          window?.innerWidth - mouseStartX > SAFE_SPACE_OFFSET
        ) {
          props.onClose(event, reason)
        }
      }}
      data-mobile-header="modal"
    ></StyledDialog>
  ) : null
}

export default ResponsiveModal
