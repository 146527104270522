import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { White, Primary450, Gray200, Gray300 } from '@mehilainen/mds-customer/colors'

import { BaseTileBoxShadow } from '../../../constants'
import { breakpoint } from '../../utils/breakpoint'
import { scale } from '../../utils/scale'

const BaseTile = styled.li<{ skeleton?: boolean; minHeight?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${scale(2)};
  background: ${White};
  cursor: pointer;
  position: relative;

  @media (min-width: ${breakpoint.sm}px) {
    border: 2px solid ${White};
    border-radius: ${scale(1)};
    box-shadow: ${BaseTileBoxShadow};

    &:hover {
      border-color: ${Gray300};
    }

    &:focus {
      border-color: ${Primary450};
    }
  }

  ${(props) =>
    props.skeleton &&
    css`
      min-height: ${props.minHeight ? props.minHeight : '100px'};
      background: ${Gray200};
    `}
`

export default BaseTile
