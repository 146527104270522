import styled from '@emotion/styled'
import { Gray200 } from '@mehilainen/mds-customer/colors'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { PriceEstimate } from '../../../__generated__/api'
import { scale } from '../../utils/scale'
import { formatPrice } from '../../utils/text'
import { ColumnFlex } from '../Layout/Layout'
import { Text } from '../Typography/Typography'

const Container = styled(ColumnFlex)`
  table {
    font-size: 0.938rem;

    th {
      font-weight: 500;
    }

    td {
      font-weight: 400;
    }

    th,
    td {
      padding: 10px;
      text-align: left;
    }

    th:nth-of-type(2) {
      text-align: right;
    }

    tbody {
      tr:nth-of-type(odd) {
        background-color: ${Gray200};
      }

      td:nth-of-type(2) {
        text-align: right;
      }
    }
  }

  ${Text} + ${Text} {
    margin-top: ${scale(2)};
  }

  ${Text} + table {
    margin-top: ${scale(2.5)};
  }
`

interface Props {
  priceEstimates: PriceEstimate[]
}

const PractitionerPriceDetails: React.FC<React.PropsWithChildren<Props>> = ({ priceEstimates }) => {
  const { t, i18n } = useTranslation()

  if (priceEstimates.length === 0) {
    return null
  }

  return (
    <Container>
      <Text as="h2" $height="Medium" $size={500} $weight="Medium">
        {t('component.priceDetails.header')}
      </Text>
      <Text $height="Large">{t('component.priceDetails.description')}</Text>
      <table>
        <thead>
          <tr>
            <th>{t('component.priceDetails.heading.service')}</th>
            <th>{t('component.priceDetails.heading.priceEstimate')}</th>
          </tr>
        </thead>
        <tbody>
          {priceEstimates.map((priceEstimate, idx) => (
            <tr key={idx}>
              <td>{priceEstimate.name}</td>
              <td>{formatPrice(priceEstimate.minPrice, priceEstimate.maxPrice, i18n.language)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  )
}

export default PractitionerPriceDetails
