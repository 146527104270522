import { css } from '@emotion/react'
import { Error400, Gray100, Gray500, Gray600, Primary500 } from '@mehilainen/mds-customer/colors'

export const commonInputStylesNew = (error: boolean) => css`
  border: 1px solid ${Gray500};
  border-radius: 4px;
  background-color: ${Gray100};
  margin-bottom: 0 !important;

  padding: 9px 16px;

  font-size: 1rem;
  line-height: 1;

  ${error
    ? css`
        border: 1px solid ${Error400};
        outline: none;
      `
    : css`
        &:focus {
          border: 1px solid ${Primary500};
          outline: none;
        }

        &:hover:not(:focus) {
          border: 1px solid ${Gray600};
        }
      `}
`
