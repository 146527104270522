import styled from '@emotion/styled'
import { Gray800, Primary500 } from '@mehilainen/mds-customer/colors'
import React, { ReactNode, useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { ReservationPaymentType } from '../../../__generated__/api'
import { DefaultButton } from '../../../common/components/DefaultButton/DefaultButton'
import { CenteredColumnFlex, ColumnFlex, MinMaxDiv } from '../../../common/components/Layout/Layout'
import RadioButtonGroup from '../../../common/components/RadioButtonGroup/RadioButtonGroup'
import { Text } from '../../../common/components/Typography/Typography'
import * as Analytics from '../../../common/utils/analytics'
import { scale } from '../../../common/utils/scale'

const ContentContainer = styled(MinMaxDiv)`
  margin-top: 24px;
  justify-self: center;
  padding: 0 ${scale(1)};
`

const ButtonContainer = styled(CenteredColumnFlex)`
  gap: ${scale(1.5)};
`

const PaymentTypeDetailsContainer = styled(ColumnFlex)`
  gap: ${scale(2)};
`

interface Props {
  isDentalAppointment: boolean
  options: ReservationPaymentType[]
  initialSelection?: ReservationPaymentType
  onSelect(value: ReservationPaymentType): void
  onBack(): void
  onBackToStart(): void
}

const PaymentTypeSelect: React.FC<React.PropsWithChildren<Props>> = ({
  isDentalAppointment,
  options,
  initialSelection,
  onSelect,
  onBack,
}) => {
  const { t, i18n } = useTranslation()
  const [value, setValue] = useState<ReservationPaymentType>(
    initialSelection ?? ReservationPaymentType.Self
  )
  const sortedOptions = useMemo(
    () =>
      [
        ReservationPaymentType.Self,
        ReservationPaymentType.PhysiotherapyReferral,
        ReservationPaymentType.HealthContract,
        ReservationPaymentType.Synsam,
        ReservationPaymentType.GiftCard,
        ReservationPaymentType.Insurance,
        ReservationPaymentType.ServiceVoucher,
      ].filter((option) => options.includes(option)),
    [options]
  )

  const ot = useCallback(
    (paymentType: ReservationPaymentType, key: string) => {
      if (
        isDentalAppointment &&
        i18n.exists(`component.paymentTypeSelect.option.${paymentType}.dental.${key}`)
      ) {
        return t(`component.paymentTypeSelect.option.${paymentType}.dental.${key}`)
      }
      return t(`component.paymentTypeSelect.option.${paymentType}.${key}`)
    },
    [isDentalAppointment, i18n, t]
  )

  const renderOptionHeader = (paymentType: ReservationPaymentType): ReactNode => {
    return ot(paymentType, 'heading')
  }

  const renderOptionDescription = (
    paymentType: ReservationPaymentType,
    checked: boolean
  ): ReactNode => {
    const text = (
      <Text $size={200} $height="Large" $weight="Regular">
        {ot(paymentType, 'text')}
      </Text>
    )

    // Allways display description for `ReservationPaymentType.Self`
    if (paymentType === ReservationPaymentType.Self) {
      return text
    }

    // Others descriptions hidden behind selection
    if (!checked) {
      return null
    }

    switch (paymentType) {
      case ReservationPaymentType.ServiceVoucher:
        return (
          <PaymentTypeDetailsContainer>
            {text}
            <Text
              as="a"
              href={ot(paymentType, 'ctaLink')}
              $size={300}
              $height="Large"
              $color={Primary500}
            >
              {ot(paymentType, 'cta')}
            </Text>
            <Text $color={Gray800} $size={200}>
              {ot(paymentType, 'price')}
            </Text>
          </PaymentTypeDetailsContainer>
        )
      case ReservationPaymentType.Insurance:
        return (
          <PaymentTypeDetailsContainer>
            <Text $size={200} $height="Large" $weight="Regular" style={{ whiteSpace: 'pre-line' }}>
              <Trans
                i18nKey={
                  isDentalAppointment &&
                  i18n.exists(`component.paymentTypeSelect.option.${paymentType}.dental.text`)
                    ? `component.paymentTypeSelect.option.${paymentType}.dental.text`
                    : `component.paymentTypeSelect.option.${paymentType}.text`
                }
                components={{
                  linkTag: <Text as="a" $size={200} $height="Large" $weight="Regular" />,
                  phoneTag: <Text as="a" $size={200} $height="Large" $weight="Regular" />,
                }}
              />
            </Text>
          </PaymentTypeDetailsContainer>
        )
      case ReservationPaymentType.HealthContract:
      case ReservationPaymentType.Synsam:
      case ReservationPaymentType.GiftCard:
      case ReservationPaymentType.PhysiotherapyReferral:
        return text
    }

    return null
  }

  const validSelection = value !== ReservationPaymentType.ServiceVoucher

  return (
    <ContentContainer size="540px">
      <CenteredColumnFlex>
        <Text as="h1" $size={400} $height="Medium" $weight="Medium">
          {t('component.paymentTypeSelect.heading')}
        </Text>
      </CenteredColumnFlex>
      <RadioButtonGroup
        options={sortedOptions}
        renderOptionHeader={renderOptionHeader}
        renderOptionDescription={renderOptionDescription}
        onChange={setValue}
        value={value}
      />
      <ButtonContainer>
        <DefaultButton
          onClick={() => {
            Analytics.trackPaymentTypeSelect(value)
            onSelect(value)
          }}
          disabled={!validSelection}
          data-cy="paymentTypeSelect-continue"
        >
          {t('common.continue')}
        </DefaultButton>
        <DefaultButton variant="outlined" onClick={onBack}>
          {t('common.back')}
        </DefaultButton>
      </ButtonContainer>
    </ContentContainer>
  )
}

export default PaymentTypeSelect
