import styled from '@emotion/styled'
import { Gray100 } from '@mehilainen/mds-customer/colors'
import { ExclamationCircle } from '@mehilainen/mds-customer/icons'
import { Button, Checkbox as MuiCheckbox } from '@mui/material'
import { FormControlLabel } from '@mui/material'
import { Form, Formik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { CancellationInfo } from '../../../__generated__/api'
import AnchorButton from '../../../common/components/AnchorButton/AnchorButton'
import AppointmentDetails from '../../../common/components/AppointmentDetails/AppointmentDetails'
import Card from '../../../common/components/Card/Card'
import { ColumnFlex } from '../../../common/components/Layout/Layout'
import { getAV3HomeUrl } from '../../../common/utils/url'
import { FormControlLabelBorderColor } from '../../../constants'

const FormContainer = styled(ColumnFlex)`
  gap: 12px;
`

const CancellationNotesContainer = styled(ColumnFlex)`
  background: ${Gray100};
  padding: 16px;
  border-radius: 8px;
  max-width: 530px;
  margin: 32px 0;
`

const Checkbox = styled(MuiCheckbox)``

const StyledFormControlLabel = styled(FormControlLabel)`
  margin: 0;
  padding: 16px;
  padding-left: 32px;
  border-radius: 4px;
  border: 1px solid ${FormControlLabelBorderColor};
  background: ${Gray100};

  :focus-within {
    outline: auto;
  }

  ${Checkbox} {
    i {
      outline: none !important;
    }
  }
`

interface Props {
  cancellationInfo: CancellationInfo
  onSubmit(): Promise<void>
}

const AppointmentCancellationForm: React.FC<React.PropsWithChildren<Props>> = ({
  cancellationInfo,
  onSubmit,
}) => {
  const { t } = useTranslation()

  const initialValues = {
    checked: false,
  }

  return (
    <Card heading={t('component.appointmentCancellationForm.heading')} icon={<ExclamationCircle />}>
      <AppointmentDetails {...cancellationInfo} />
      <CancellationNotesContainer>
        <span>{t('component.appointmentCancellationForm.paragraph1')}</span>
        <span>{t('component.appointmentCancellationForm.paragraph2')}</span>
      </CancellationNotesContainer>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validateOnChange={false}>
        {({ values, handleChange, isSubmitting }) => (
          <Form>
            <FormContainer>
              <StyledFormControlLabel
                label={t('component.appointmentCancellationForm.checkbox')}
                control={
                  <Checkbox
                    id="AppointmentCancellationForm-checked"
                    name="checked"
                    checked={values.checked}
                    onChange={handleChange}
                  />
                }
                data-cy="cancellationFormCheckbox"
              />
              <Button
                type="submit"
                disabled={isSubmitting || !values.checked}
                color="error"
                data-cy="cancellationFormSubmitButton"
                size="large"
              >
                {t('component.appointmentCancellationForm.submit')}
              </Button>
              <AnchorButton type="button" variant="outlined" size="large" href={getAV3HomeUrl()}>
                {t('component.appointmentCancellationForm.cancel')}
              </AnchorButton>
            </FormContainer>
          </Form>
        )}
      </Formik>
    </Card>
  )
}

export default AppointmentCancellationForm
