import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Primary100, Primary500, Primary700 } from '@mehilainen/mds-customer/colors'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { scale } from '../../utils/scale'
import { CenteredRowFlex, RowFlex } from '../Layout/Layout'
import { Text } from '../Typography/Typography'

type DatePickerLegendVariant = 'horizontal' | 'vertical'

const Container = styled(RowFlex)<{ $variant: DatePickerLegendVariant }>`
  gap: ${scale(2)};
  ${(props) =>
    props.$variant === 'vertical' &&
    css`
      flex-direction: column;
      align-self: flex-start;
    `}
`

interface LegendItemProps {
  background: string
  border: string
  clip?: boolean
}

const LegendItem = styled('div', {
  shouldForwardProp: (prop) => !['border', 'background', 'clip'].includes(prop),
})<LegendItemProps>`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.border};
  background: ${(props) => props.background};
  ${(props) =>
    props.clip &&
    css`
      clip-path: polygon(100% 100%, 0% 0, -50% 150%);
    `}
`

const ItemContainer = styled(CenteredRowFlex)`
  ${LegendItem} {
    margin-right: ${scale(0.5)};
  }
`

interface Props {
  variant?: DatePickerLegendVariant
  className?: string
}

const DatePickerLegend: React.FC<React.PropsWithChildren<Props>> = ({
  variant = 'horizontal',
  className,
}) => {
  const { t } = useTranslation()
  return (
    <Container $variant={variant} className={className} aria-hidden>
      <ItemContainer>
        <LegendItem background={Primary100} border={Primary500} />
        <Text $size={300} $weight="Regular" $height="Small">
          {t('component.datePickerLegend.available')}
        </Text>
      </ItemContainer>
      <ItemContainer>
        <LegendItem background={Primary500} border={Primary500} />
        <Text $size={300} $weight="Regular" $height="Small">
          {t('component.datePickerLegend.selected')}
        </Text>
      </ItemContainer>
      <ItemContainer>
        <LegendItem background={Primary700} border={Primary700} clip />
        <Text $size={300} $weight="Regular" $height="Small">
          {t('component.datePickerLegend.today')}
        </Text>
      </ItemContainer>
    </Container>
  )
}

export default styled(DatePickerLegend)``
