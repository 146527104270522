import styled from '@emotion/styled'
import { Primary600 } from '@mehilainen/mds-customer/colors'
import { Tab, Tabs } from '@mui/material'
import React from 'react'

import { useIsMobile } from '../../hooks/useBreakpoint'

const StyledTabs = styled(Tabs, {
  shouldForwardProp: (prop) => !['isMobile'].includes(prop),
})<{ isMobile: boolean }>`
  .MuiTab-root {
    border: none;
    background: white;
    opacity: 1;
    font-weight: 500;
    text-transform: none;
    min-height: 66px;
    font-size: ${(props) => (props.isMobile ? '0.875rem' : '0.938rem')};
  }

  .Mui-selected {
    color: ${Primary600};
  }

  .MuiTabs-indicator {
    height: 6px;
    background: ${Primary600};
  }
`

const StyledTab = styled(Tab)`
  &.Mui-focusVisible {
    outline: auto;
    outline-offset: -1px;
  }
`

interface Props {
  value: boolean
  ariaLabel: string
  mobileTrueLabel: string
  trueLabel: string
  mobileFalseLabel: string
  falseLabel: string
  onToggle(value: boolean): void
  className?: string
  trueDaPath: string
  falseDaPath: string
}

const Toggle: React.FC<React.PropsWithChildren<Props>> = ({
  value,
  ariaLabel,
  falseLabel,
  trueLabel,
  mobileFalseLabel,
  mobileTrueLabel,
  onToggle,
  className,
  trueDaPath,
  falseDaPath,
}) => {
  const isMobile = useIsMobile()

  return (
    <StyledTabs
      aria-label={ariaLabel}
      value={value ? 0 : 1}
      onChange={(event, changeValue) => (changeValue === 1 ? onToggle(false) : onToggle(true))}
      variant="fullWidth"
      className={className}
      isMobile={isMobile}
    >
      <StyledTab
        disableRipple
        label={isMobile ? mobileTrueLabel : trueLabel}
        data-cy="toggleTrueTab"
        data-analytics-path={trueDaPath}
      />
      <StyledTab
        disableRipple
        label={isMobile ? mobileFalseLabel : falseLabel}
        data-cy="toggleFalseTab"
        data-analytics-path={falseDaPath}
      />
    </StyledTabs>
  )
}

export default Toggle
