import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { White, Primary500, Primary100 } from '@mehilainen/mds-customer/colors'

import { localizeRating } from '../../utils/text'

import PlaceholderImage from './placeholder.jpg'
import ResourceListPlaceHolder from './resource-list-default.svg'

export const resourceListImage = 'resource-list-placeholder'
// ${ResourceListPlaceHolder} & {PlaceholderImage} need to be surrounded by double quotes to work with url() in Vite
// prettier-ignore
const Avatar = styled.div<{
  image?: string | null
  size?: string
  width?: string
  height?: string
  borderRadius?: string
  rating?: number
  ratingFontSize?: string
  ratingPadding?: string
}>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-shrink: 0;
  width: ${(props) => props.width ?? props.size ?? '32px'};
  height: ${(props) => props.height ?? props.size ?? '32px'};
  border-radius: ${(props) => props.borderRadius ?? '50%'};
  background: ${Primary100};
  background-size: cover;
  background-position: center;
  outline-offset: 3px;

  ${(props) =>
    props.image === resourceListImage
      ? css`
          background-image: url("${ResourceListPlaceHolder}");
        `
      : props.image
      ? css`
          background-image: url(${process.env.REACT_APP_IMAGE_PATH}${props.image});
        `
      : css`
          background-image: url("${PlaceholderImage}");
        `
  }

  ${(props) =>
    props.rating &&
    css`
      &::after {
        content: '${localizeRating(props.rating)}';
        font-size: ${props.ratingFontSize ?? '0.938rem'};
        line-height: 1;
        border-radius: 4px 0 ${props.borderRadius ?? '4px'} 0;
        color: ${White};
        background: ${Primary500};
        padding: ${props.ratingPadding ?? '8px'};
        align-self: flex-end;
      }
    `}
`

export default Avatar
