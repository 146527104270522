import styled from '@emotion/styled'
import { Black, Error400, Gray300, Gray900 } from '@mehilainen/mds-customer/colors'
import { ExclamationCircle } from '@mehilainen/mds-customer/icons'
import { Button } from '@mui/material'

import {
  CenteredColumnFlex,
  ColumnFlex,
  MinMaxDiv,
  RowFlex,
} from '../../../../common/components/Layout/Layout'
import { Text } from '../../../../common/components/Typography/Typography'
import { scale } from '../../../../common/utils/scale'

export const Container = styled(MinMaxDiv)`
  padding: ${scale(2)};
`
Container.defaultProps = { size: '740px' }

export const ContentContainer = styled(CenteredColumnFlex)`
  gap: ${scale(2)};
`

export const Heading = styled(Text)``
Heading.defaultProps = { as: 'h1', $size: 500, $weight: 'Medium', $color: Gray900 }

export const Paragraph = styled(Text)`
  white-space: pre-wrap;
  text-align: center;
`
Paragraph.defaultProps = {
  as: 'p',
  $size: 300,
  $weight: 'Regular',
  $height: 'Large',
  $color: Gray900,
}

export const ButtonsContainer = styled(CenteredColumnFlex)`
  gap: ${scale(1.5)};
  width: 100%;
`

export const StyledExclamationCircle = styled(ExclamationCircle)`
  width: 64px;
  height: 64px;
`
StyledExclamationCircle.defaultProps = { htmlColor: Error400 }

export const CheckboxContainer = styled.div`
  padding: ${scale(2)};
  border-radius: 4px;
  border: 1px solid ${Gray300};
`

export const ModalContentsContainer = styled(ColumnFlex)`
  gap: ${scale(2)};

  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.7;
  color: ${Gray900};

  h1 {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.4;
    color: ${Black};
  }

  ul {
    list-style: inside;
  }
`

export const ButtonContent = styled(RowFlex)`
  width: 100%;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  text-align: start;
`

export const StyledButton = styled(Button)`
  height: auto;
  padding: 20px;
  width: 100%;
`

export const LongText = styled(Paragraph)`
  white-space: pre-wrap;
  text-align: left;
`
