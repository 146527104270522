import styled from '@emotion/styled'
import { Error100, Error800 } from '@mehilainen/mds-customer/colors'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'

import { PractitionerDetails } from '../../../__generated__/api'
import { selectedInsuranceSelector } from '../../../state/common/selectors'
import { scale } from '../../utils/scale'

const Warning = styled.div`
  padding: ${scale(1)};
  color: ${Error800};
  background: ${Error100};
  border-radius: ${scale(1)};
  margin-bottom: ${scale(3)};
`

interface PractitionerInsuranceUnsupportedProps {
  practitionerDetails?: PractitionerDetails
}

export const PractitionerInsuranceUnsupported: React.FC<
  React.PropsWithChildren<PractitionerInsuranceUnsupportedProps>
> = ({ practitionerDetails }) => {
  const { t } = useTranslation()
  const selectedInsurance = useRecoilValue(selectedInsuranceSelector)

  if (!practitionerDetails || !selectedInsurance || selectedInsurance.id === 'other') {
    return null
  }

  if (practitionerDetails.insuranceContractIds.includes(selectedInsurance.id as number)) {
    return null
  }

  return <Warning>{t('component.practitionerInsurance.unsupportedInsuranceError')}</Warning>
}
