import styled from '@emotion/styled'
import { Black, Primary450, Primary500, Attention500 } from '@mehilainen/mds-customer/colors'
import { ArrowRight, CircleInfo } from '@mehilainen/mds-customer/icons'
import { Button } from '@mui/material'
import dayjs from 'dayjs'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { AppointmentSearchResult } from '../../../__generated__/api'
import { scale } from '../../utils/scale'
import { formatDateAndTime } from '../../utils/text'
import Avatar from '../Avatar/Avatar'
import { CenteredRowFlex, ColumnFlex, RowFlex } from '../Layout/Layout'
import { Text } from '../Typography/Typography'

import BaseTile from './BaseTile'

const StyledBaseTile = styled(BaseTile)`
  &:focus-within {
    border-color: ${Primary450};
  }
`

const RowContainer = styled(RowFlex)<{ variant: VariantOptions }>`
  width: 100%;
  flex: 1;
  align-items: flex-start;
  gap: ${scale(1.5)};
`

const ColumnContainer = styled(ColumnFlex)`
  flex: 1;
`

const CenteredContainer = styled(CenteredRowFlex)`
  flex: 1;
  gap: ${scale(0.5)};
`

const NextAppointmentDateContainer = styled(CenteredRowFlex)`
  margin-top: ${scale(1)};

  ${Text} {
    margin-right: ${scale(0.5)};
  }

  svg {
    width: 13px;
    height: 13px;
    color: ${Black};
  }
`

const TextContainer = styled(ColumnFlex)`
  flex: 1;
  gap: ${scale(0.5)};

  ${Text} {
    word-break: break-word;
    hyphens: auto;
  }
`

const TextWithIconContainer = styled(Text)<{ $align: 'center' | 'flex-start' }>`
  display: flex;
  align-items: ${(props) => props.$align};
  gap: ${scale(1)};
`

const StyledButton = styled(Button)`
  align-self: flex-start;
  margin-left: ${scale(2)};
`

type VariantOptions = 'S' | 'M' | 'L'

interface Props {
  appointment: AppointmentSearchResult
  variant: VariantOptions
  onClick?(): void
  onSpecialistClick?(): void
}

const SpecialistTile: React.FC<React.PropsWithChildren<Props>> = ({
  appointment,
  variant,
  onClick,
  onSpecialistClick,
}) => {
  const { t } = useTranslation()
  const defaultTextSize = variant === 'S' ? 300 : variant === 'M' ? 300 : 400
  const onClickHandler = useCallback(
    (event: any) => {
      event.stopPropagation()
      onSpecialistClick?.()
    },
    [onSpecialistClick]
  )

  const onKeyHandler = useCallback(
    (event: any) => {
      if (event.key === 'Enter') {
        event.stopPropagation()
        onSpecialistClick?.()
      }
    },
    [onSpecialistClick]
  )

  return (
    <StyledBaseTile onClick={onClick} onKeyPress={onClick}>
      <RowContainer variant={variant}>
        <Avatar
          onClick={onSpecialistClick ? onClickHandler : undefined}
          onKeyPress={onSpecialistClick ? onKeyHandler : undefined}
          width={variant === 'S' ? '50px' : '64px'}
          height={variant === 'S' ? '70px' : '84px'}
          image={appointment.image}
          rating={appointment.specialistRating}
          borderRadius="10px"
          ratingFontSize="11px"
          ratingPadding="4px"
          tabIndex={0}
        />
        <ColumnContainer>
          <CenteredContainer>
            <TextContainer>
              <TextWithIconContainer
                $size={defaultTextSize}
                $height="Medium"
                $weight="Bold"
                $color={onSpecialistClick ? `${Primary500}` : `${Black}`}
                $align="center"
                onClick={onSpecialistClick ? onClickHandler : undefined}
              >
                <span data-cy="specialistName">{appointment.specialistName}</span>
                {onSpecialistClick && <CircleInfo />}
              </TextWithIconContainer>
              <Text $size={defaultTextSize} $height="Medium" $weight="Regular">
                {appointment.specialistTitle}
              </Text>
            </TextContainer>
            <StyledButton size="small" data-analytics-path="acc-person-free-rec-time">
              {t('component.practitionerList.additionalInformationButtonText')}
            </StyledButton>
          </CenteredContainer>
          {appointment.specialistAllowsNewKelaClients === false && (
            <TextWithIconContainer $size={200} $color={Attention500} $align="flex-start">
              <CircleInfo />
              <span>{t('component.appointmentList.disallowsKelaClients')}</span>
            </TextWithIconContainer>
          )}
          <NextAppointmentDateContainer>
            <Text $size={defaultTextSize} $weight="Regular" $height="Small">
              {formatDateAndTime(dayjs(appointment.time), t)}
            </Text>
            <ArrowRight />
          </NextAppointmentDateContainer>
        </ColumnContainer>
      </RowContainer>
    </StyledBaseTile>
  )
}

export default SpecialistTile
