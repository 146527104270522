import styled from '@emotion/styled'
import { Gray200, Primary500 } from '@mehilainen/mds-customer/colors'

const Loader = styled.div`
  border: 10px solid ${Gray200};
  border-top: 10px solid ${Primary500};
  border-radius: 50%;
  width: 65px;
  height: 65px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
export default Loader
