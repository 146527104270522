import styled from '@emotion/styled'
import { Primary500 } from '@mehilainen/mds-customer/colors'
import { AngleRight } from '@mehilainen/mds-customer/icons'
import dayjs, { Dayjs } from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { SearchAppointmentSuggestion } from '../../../__generated__/api'
import { ColumnFlex } from '../Layout/Layout'

const Container = styled(ColumnFlex)`
  margin: 25px 0;
  text-align: left;
  align-items: left;
  font-size: 1.25rem;
  gap: 10px;
`

const SectionHeader = styled.span`
  font-size: 0.938rem;
  font-weight: 400;
  &:not(:first-of-type) {
    margin-top: 15px;
  }
`

const SuggestionAnchor = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 0.938rem;
  font-weight: 400;
  padding: 0;
  color: ${Primary500};
`

interface SuggestionProps {
  id: string
  onSelect: (id: string) => void
}

const Suggestion: React.FC<React.PropsWithChildren<SuggestionProps>> = ({
  id,
  onSelect,
  children,
}) => {
  const onClick = (event: React.SyntheticEvent<HTMLButtonElement>) =>
    event.currentTarget.dataset.id && onSelect(event.currentTarget.dataset.id)

  return (
    <SuggestionAnchor data-id={id} onClick={onClick} data-analytics-path="alt-search">
      {children} <AngleRight />
    </SuggestionAnchor>
  )
}

interface Props {
  suggestions: SearchAppointmentSuggestion[]
  onSelect: (id: string, type: 'location' | 'node' | 'date', date?: Dayjs) => void
}

const AppointmentSearchSuggestions: React.FC<React.PropsWithChildren<Props>> = ({
  suggestions,
  onSelect,
}) => {
  const { t } = useTranslation()

  const availableDates = suggestions.filter((s) => s.type === 'date')
  const availableLocations = suggestions.filter((s) => s.type === 'location')
  const availableNodes = suggestions.filter((s) => s.type === 'node')

  const onDateSelect = (id: string, date?: Dayjs) => onSelect(id, 'date', date)
  const onLocationSelect = (id: string, date?: Dayjs) => onSelect(id, 'location', date)
  const onNodeSelect = (id: string, date?: Dayjs) => onSelect(id, 'node', date)

  return (
    <Container>
      {availableDates.length > 0 && (
        <>
          <SectionHeader>{t('component.suggestionList.availableDates')}</SectionHeader>
          {availableDates.map((i, index) => (
            <Suggestion
              id={i.id}
              onSelect={(id) => onDateSelect(id, i.date ? dayjs(i.date) : undefined)}
              key={`date-${index}-${i.id}`}
            >
              {i.title}
            </Suggestion>
          ))}
        </>
      )}
      {availableLocations.length > 0 && (
        <>
          <SectionHeader>{t('component.suggestionList.availableLocations')}</SectionHeader>
          {availableLocations.map((i, index) => (
            <Suggestion
              id={i.id}
              onSelect={(id) => onLocationSelect(id, i.date ? dayjs(i.date) : undefined)}
              key={`location-${index}-${i.id}`}
            >
              {i.title}
            </Suggestion>
          ))}
        </>
      )}
      {availableNodes.length > 0 && (
        <>
          <SectionHeader>{t('component.suggestionList.availableSpecialties')}</SectionHeader>
          {availableNodes.map((i, index) => (
            <Suggestion
              id={i.id}
              onSelect={(id) => onNodeSelect(id, i.date ? dayjs(i.date) : undefined)}
              key={`node-${index}-${i.id}`}
            >
              {i.title}
            </Suggestion>
          ))}
        </>
      )}
    </Container>
  )
}

export default AppointmentSearchSuggestions
