import styled from '@emotion/styled'
import { Primary500 } from '@mehilainen/mds-customer/colors'
import { ArrowLeft } from '@mehilainen/mds-customer/icons'
import { Skeleton } from '@mui/material'
import dayjs from 'dayjs'
import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import {
  AppointmentPrice,
  AppointmentSource,
  AppointmentType,
  ContractRuleStatus,
  ElectronicHealthRecordSystem,
  NotificationStyle,
  SupportedLanguage,
} from '../../../__generated__/api'
import { AppointmentTypeOption } from '../../../common/components/AppointmentTypeSelect/AppointmentTypeSelect'
import Breadcrumb from '../../../common/components/Breadcrumb/Breadcrumb'
import { DefaultButton } from '../../../common/components/DefaultButton/DefaultButton'
import { CenteredColumnFlex, MinMaxDiv, RowFlex } from '../../../common/components/Layout/Layout'
import Notification from '../../../common/components/Notification/Notification'
import { Text } from '../../../common/components/Typography/Typography'
import { useApi } from '../../../common/hooks/useApi'
import { useAppointmentTitle } from '../../../common/hooks/useAppointmentTitle'
import { useIsMobile } from '../../../common/hooks/useBreakpoint'
import useLoginState from '../../../common/hooks/useLoginState'
import { useNode } from '../../../common/hooks/useNode'
import { useOHC } from '../../../common/hooks/useOHC'
import useTranslation from '../../../common/hooks/useTranslation'
import api from '../../../common/services/api'
import * as Analytics from '../../../common/utils/analytics'
import { maxWidth } from '../../../common/utils/breakpoint'
import { ReserveAppointmentNotFoundError } from '../../../common/utils/error/ReserveAppointmentNotFoundError'
import { scale } from '../../../common/utils/scale'
import { stripTagsAllowBasicHtml } from '../../../common/utils/xss'
import {
  AppointmentId,
  authenticationModalOpenAtom,
  isFromAppAtom,
  selectedAppointmentIdAtom,
  selectedAppointmentLengthAtom,
  selectedAppointmentNodeConnectionIdAtom,
  selectedAppointmentNodeIdAtom,
  selectedAppointmentServiceIdAtom,
  selectedAppointmentTypeAtom,
} from '../../../state/common/atoms'
import { InsurancePaymentType, selectedInsuranceSelector } from '../../../state/common/selectors'
import { initialReserveHistoryRestoredAtom } from '../../../state/reserve/atoms'
import { controlSearchNodeModal } from '../../../state/search/atoms'
import { isPaidByOHC } from '../../payerSelection/utils'
import AuthenticationModal from '../../reserve/components/AuthenticationModal'
import PriceDisclaimerModal from '../../reserve/components/PriceDisclaimerModal'
import useReserveHistory from '../../reserve/hooks/useReserveHistory'
import useSearchTarget, { SearchTarget } from '../../search/hooks/useSearchTarget'
import TypeSelect from '../components/TypeSelect'

const Container = styled(CenteredColumnFlex)`
  min-height: 100%;
  margin-bottom: ${scale(12)};
`

const BreadCrumbContainer = styled(MinMaxDiv)`
  padding-left: 27px;
  @media (min-width: ${maxWidth.banner}px) {
    width: ${maxWidth.banner}px;
  }
`

const ContentContainer = styled(MinMaxDiv)`
  margin: 24px 16px 0;
  justify-self: center;
`

const ButtonContainer = styled(CenteredColumnFlex)`
  gap: ${scale(2)};
`

const SummaryContainer = styled(CenteredColumnFlex)`
  width: 100%;
  background-color: white;
`

const PriceContainer = styled(Text)`
  font-size: 0.688rem;
  text-align: center;
`
// eslint-disable-next-line @typescript-eslint/no-explicit-any
PriceContainer.defaultProps = { 'data-cy': 'priceContainer' } as any

const BackContainer = styled(RowFlex)`
  gap: 8px;
  align-items: center;
  & > * {
    color: ${Primary500};
  }
`

const StyledButton = styled.button`
  display: block;
  border: none;
  cursor: pointer;
  background-color: transparent;
  color: ${Primary500};
`

const Skeletons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const AppointmentPriceInfo: React.FC<React.PropsWithChildren<AppointmentPrice>> = ({
  price,
  minPrice,
  maxPrice,
  priceAfterKelaCompensation,
  minPriceAfterKelaCompensation,
  maxPriceAfterKelaCompensation,
  source,
  pricingInfo,
}) => {
  const { t, i18n } = useTranslation()
  const locale = i18n.language
  const options = { minimumFractionDigits: 2 }

  if (source === ElectronicHealthRecordSystem.Assisdent && pricingInfo) {
    return (
      <PriceContainer
        $height="Large"
        dangerouslySetInnerHTML={{
          __html: stripTagsAllowBasicHtml(pricingInfo),
        }}
      />
    )
  }

  if (minPrice && maxPrice) {
    if (minPriceAfterKelaCompensation && maxPriceAfterKelaCompensation) {
      return (
        <PriceContainer $height="Large">
          {t('component.appointmentOptionsSelect.priceHeadingWithKelaAndRange', {
            min_price: minPrice.toLocaleString(locale, options),
            max_price: maxPrice.toLocaleString(locale, options),
            min_price_with_kela: minPriceAfterKelaCompensation.toLocaleString(locale, options),
            max_price_with_kela: maxPriceAfterKelaCompensation.toLocaleString(locale, options),
          })}
        </PriceContainer>
      )
    }

    return (
      <PriceContainer $height="Large">
        {t('component.appointmentOptionsSelect.priceHeadingWithRange', {
          min_price: minPrice.toLocaleString(locale, options),
          max_price: maxPrice.toLocaleString(locale, options),
        })}
      </PriceContainer>
    )
  }

  if (priceAfterKelaCompensation) {
    return (
      <PriceContainer $height="Large">
        {t('component.appointmentOptionsSelect.priceHeadingWithKela', {
          price: price.toLocaleString(locale, options),
          price_with_kela: priceAfterKelaCompensation.toLocaleString(locale, options),
        })}
      </PriceContainer>
    )
  }

  return (
    <PriceContainer $height="Large">
      {t('component.appointmentOptionsSelect.priceHeading', {
        price: price.toLocaleString(locale, options),
      })}
    </PriceContainer>
  )
}

interface Props {
  onSelect(appointmentId: AppointmentId): void
  onNavigate(searchTarget: SearchTarget): void
  onBack?(appointmentId: AppointmentId, practitionerId: number): void
}

const AppointmentOptionSelectView: React.FC<React.PropsWithChildren<Props>> = ({
  onSelect,
  onBack,
  onNavigate,
}) => {
  useReserveHistory()
  const isMobile = useIsMobile()
  const { isOHCSide } = useOHC()

  const { breadcrumb } = useSearchTarget()
  const { t, i18n } = useTranslation()
  const { appointmentId: appointmentIdFromParams } = useParams<{ appointmentId?: string }>()
  const [appointmentId, setAppointmentId] = useRecoilState(selectedAppointmentIdAtom)
  const [selectedAppointmentType, setSelectedAppointmentType] = useRecoilState(
    selectedAppointmentTypeAtom
  )
  const setModalOpen = useSetRecoilState(authenticationModalOpenAtom)
  const selectedDuration = useRecoilValue(selectedAppointmentLengthAtom)
  const initialReserveHistoryRestored = useRecoilValue(initialReserveHistoryRestoredAtom)
  const selectedAppointmentNodeId = useRecoilValue(selectedAppointmentNodeIdAtom)
  const selectedService = useRecoilValue(selectedAppointmentServiceIdAtom)
  const selectedInsurance = useRecoilValue(selectedInsuranceSelector)
  const setControlSearchNodeModal = useSetRecoilState(controlSearchNodeModal)

  const { node, pending: nodePending } = useNode(selectedAppointmentNodeId)
  const appointmentTitle = useAppointmentTitle()

  const [selectedAppointmentTypeOption, setSelectedAppointmentTypeOption] = useState<
    AppointmentTypeOption | undefined
  >(undefined)
  const [priceDisclaimerModalOpen, setPriceDisclaimerModalOpen] = useState<boolean>(false)
  const { loginStatus } = useLoginState()
  const isFromApp = useRecoilValue(isFromAppAtom)

  const selectedNodeConnectionId = useRecoilValue(selectedAppointmentNodeConnectionIdAtom)
  const { data: appointmentInfo, error: appointmentInfoError } = useApi(
    api.v1.appointmentInfo,
    {
      appointmentId: String(appointmentId),
      lang: i18n.language as SupportedLanguage,
      ...(isOHCSide && {
        nodeId: selectedAppointmentNodeId,
        connectionId: selectedNodeConnectionId,
        isOHC: true,
      }),
    },
    null,
    Boolean(appointmentId)
  )

  const { data: priceOptions } = useApi(
    api.v1.appointmentPrice,
    {
      lang: i18n.language as SupportedLanguage,
      appointmentId: appointmentId || 1,
      date: dayjs(appointmentInfo?.date).toISOString(),
      nodeId: selectedAppointmentNodeId,
      connectionId: selectedNodeConnectionId,
      serviceId: selectedService,
      duration: selectedDuration
        ? selectedDuration === 'default'
          ? undefined
          : selectedDuration
        : undefined,
    },
    null,
    Boolean(appointmentId) && Boolean(appointmentInfo)
  )

  const { data: bookingInstructions } = useApi(
    api.v1.getBookingInstructions,
    { lang: i18n.language, nodeId: selectedAppointmentNodeId, serviceId: selectedService },
    null,
    Boolean(selectedAppointmentNodeId || selectedService)
  )

  const typeOptions: AppointmentTypeOption[] = useMemo(() => {
    return appointmentInfo
      ? [
          ...(appointmentInfo.isClinicAppointmentPermitted
            ? [
                {
                  type: AppointmentType.Clinic,
                  clinic: appointmentInfo.locationName,
                  address: appointmentInfo.locationAddress,
                  postnumber: appointmentInfo.locationPostnumber,
                  postplace: appointmentInfo.locationPostplace,
                  locationId: appointmentInfo.locationId,
                  ohcRule: isOHCSide ? appointmentInfo.ohcClinicAppointmentRule : undefined,
                },
              ]
            : []),
          ...(appointmentInfo.isVideoAppointmentPermitted && (!node || node?.video)
            ? [
                {
                  type: AppointmentType.Video,
                  ohcRule: isOHCSide ? appointmentInfo.ohcVideoAppointmentRule : undefined,
                },
              ]
            : []),
          ...(appointmentInfo.isPhoneAppointmentPermitted && (!node || node?.phone)
            ? [
                {
                  type: AppointmentType.Phone,
                  ohcRule: isOHCSide ? appointmentInfo.ohcPhoneAppointmentRule : undefined,
                },
              ]
            : []),
        ]
      : []
  }, [appointmentInfo, isOHCSide, node])

  const hidePrice = useMemo(() => {
    if (appointmentInfo?.appointmentSource === AppointmentSource.AssisDent) {
      return false
    }

    if (isOHCSide) {
      return selectedAppointmentTypeOption?.ohcRule !== 'disallowed'
    }

    return selectedInsurance?.paymentType === InsurancePaymentType.INSURANCE_COMPANY
  }, [
    appointmentInfo?.appointmentSource,
    isOHCSide,
    selectedAppointmentTypeOption?.ohcRule,
    selectedInsurance?.paymentType,
  ])

  useEffect(() => {
    if (!appointmentId && appointmentIdFromParams && parseInt(appointmentIdFromParams, 10)) {
      setAppointmentId(parseInt(appointmentIdFromParams, 10))
    }
  }, [appointmentId, appointmentIdFromParams, setAppointmentId])

  useEffect(() => {
    if (initialReserveHistoryRestored && !selectedAppointmentTypeOption && typeOptions.length > 0) {
      let found = false
      // Check if type option is already set (by router/URL)
      typeOptions.forEach((typeOption) => {
        if (selectedAppointmentType === typeOption.type) {
          found = true
          setSelectedAppointmentTypeOption(typeOption)
        }
      })

      // Default to first type option
      if (!found) {
        setSelectedAppointmentTypeOption(typeOptions[0])
      }
    }
  }, [
    initialReserveHistoryRestored,
    selectedAppointmentTypeOption,
    typeOptions,
    selectedAppointmentType,
  ])

  useEffect(() => {
    if (
      selectedAppointmentTypeOption &&
      selectedAppointmentType !== selectedAppointmentTypeOption.type &&
      selectedAppointmentType !== AppointmentType.Callback
    ) {
      setSelectedAppointmentType(selectedAppointmentTypeOption.type)
    }
  }, [selectedAppointmentType, selectedAppointmentTypeOption, setSelectedAppointmentType])

  const handleAuthSelectClick = (continueToAuthFlow: boolean, appId: AppointmentId) => {
    if (continueToAuthFlow) {
      setModalOpen(true)
    } else {
      onSelect(appId)
    }
  }

  if (appointmentInfoError) {
    if (appointmentInfoError.statusCode === 404) {
      throw new ReserveAppointmentNotFoundError()
    }
    throw appointmentInfoError
  }

  Analytics.useTrackVerify({
    appointmentInfo,
    node,
    nodePending,
    event: 'confirm_slot',
    duration: selectedDuration === 'default' ? 'regular' : 'extended',
    isOHC: isOHCSide,
    isDental: appointmentInfo?.appointmentSource === 'AssisDent',
  })

  const priceOption =
    priceOptions && priceOptions.find((p) => p.appointmentType === selectedAppointmentType)

  if (
    !appointmentId ||
    !appointmentInfo ||
    !selectedAppointmentTypeOption ||
    !initialReserveHistoryRestored
  ) {
    return (
      <Container data-mobile-header="sub">
        <MinMaxDiv size="700px" gap="0">
          <ContentContainer size="540px">
            <Skeletons>
              <Skeleton variant="rectangular" height="30px" />
              <Skeleton variant="rectangular" height="190px" />
              <Skeleton variant="rectangular" height="120px" />
              <Skeleton variant="rectangular" height="120px" />
            </Skeletons>
          </ContentContainer>
        </MinMaxDiv>
      </Container>
    )
  }

  const isBreadcrumbVisible = !isFromApp && !isMobile
  const isBackButtonVisible = !isFromApp

  let breadcrumbContent = <></> // Default to empty fragment

  if (isBreadcrumbVisible) {
    breadcrumbContent = (
      <Breadcrumb
        path={breadcrumb.slice(-2)}
        color={Primary500}
        onSelect={(selectedPath) => {
          setControlSearchNodeModal('skip')
          onNavigate(selectedPath.searchTarget)
        }}
      />
    )
  } else if (isBackButtonVisible) {
    breadcrumbContent = (
      <StyledButton
        onClick={() => {
          setControlSearchNodeModal('skip')
          onNavigate?.(breadcrumb.slice(-1)[0].searchTarget)
        }}
        style={{ paddingLeft: '0px' }}
      >
        <BackContainer>
          <ArrowLeft style={{ marginLeft: '-3px' }}></ArrowLeft>
          <Text $size={300}>{t('common.back')}</Text>
        </BackContainer>
      </StyledButton>
    )
  }
  return (
    <>
      <Container data-mobile-header="sub">
        <BreadCrumbContainer size={`${maxWidth.banner}px`}>{breadcrumbContent}</BreadCrumbContainer>
        <MinMaxDiv size="700px" gap="0">
          <ContentContainer size="540px">
            {isOHCSide &&
              selectedAppointmentTypeOption?.ohcRule &&
              (showWarningNotification(selectedAppointmentTypeOption.ohcRule) ||
                showErrorNotification(selectedAppointmentTypeOption.ohcRule)) && (
                <Notification
                  style={
                    showWarningNotification(selectedAppointmentTypeOption.ohcRule)
                      ? NotificationStyle.Warning
                      : NotificationStyle.Error
                  }
                >
                  {t(getOHCNotificationI18nPath(selectedAppointmentTypeOption.ohcRule))}
                </Notification>
              )}
            <SummaryContainer>
              <MinMaxDiv>
                <Text as="h1" $size={isMobile ? 400 : 500} $weight="Medium" $height="Large" $center>
                  {typeOptions.length > 1
                    ? t('component.appointmentOptionsSelect.typeSelectHeading')
                    : t('component.appointmentOptionsSelect.heading')}
                </Text>
                <TypeSelect
                  appointmentInfo={appointmentInfo}
                  selectedDuration={selectedDuration}
                  appointmentTypeOptions={typeOptions}
                  selectedType={selectedAppointmentTypeOption}
                  appointmentTitle={appointmentTitle}
                  bookingInstructions={bookingInstructions}
                  isCallbackAppointment={appointmentInfo.isCallbackAppointment}
                  onSelect={(selected) => {
                    setSelectedAppointmentTypeOption(selected)
                  }}
                />
                {priceOption && !hidePrice && (
                  <PriceContainer>
                    <AppointmentPriceInfo {...priceOption} />
                    {priceOption.source !== ElectronicHealthRecordSystem.Assisdent && (
                      <StyledButton
                        style={{ display: 'inline' }}
                        onClick={() => setPriceDisclaimerModalOpen(true)}
                      >
                        <Text $height="Large" style={{ fontSize: '0.688rem' }}>
                          {t('common.readMore')}
                        </Text>
                      </StyledButton>
                    )}
                  </PriceContainer>
                )}
              </MinMaxDiv>
            </SummaryContainer>
            <ButtonContainer>
              <DefaultButton
                onClick={() => {
                  Analytics.trackIdenfiticationSelect('identification')
                  handleAuthSelectClick(!loginStatus, appointmentId)
                }}
                data-cy={
                  loginStatus ? 'reserveAsAuthenticatedUserButton' : 'continueToAuthFlowButton'
                }
                data-analytics-path="identification"
              >
                {loginStatus
                  ? !isOHCSide ||
                    (selectedAppointmentTypeOption.ohcRule &&
                      isPaidByOHC(selectedAppointmentTypeOption.ohcRule))
                    ? t('common.continue')
                    : t('component.appointmentOptionsSelect.ohc.disallowedContinueButton')
                  : t('component.authenticationModal.authButtonText')}
              </DefaultButton>
              {!loginStatus && (
                <Text as="p" $size={200} $weight="Regular" $height="Large" $center>
                  {t('component.appointmentOptionsSelect.loginHelperText')}
                </Text>
              )}
              <DefaultButton
                variant="text"
                onClick={() => {
                  setControlSearchNodeModal('skip')
                  onBack?.(appointmentId, appointmentInfo.practitionerId)
                }}
              >
                {t('component.appointmentOptionsSelect.back')}
              </DefaultButton>
            </ButtonContainer>
          </ContentContainer>
        </MinMaxDiv>
      </Container>
      <PriceDisclaimerModal
        open={priceDisclaimerModalOpen}
        onClose={() => setPriceDisclaimerModalOpen(false)}
      />
      <AuthenticationModal
        authCompletedCallback={() => {
          setModalOpen(false)
          onSelect(appointmentId)
        }}
        note={t('component.appointmentOptionsSelect.authenticationModalNote')}
      />
    </>
  )
}

export default AppointmentOptionSelectView

const getOHCNotificationI18nPath = (rule: ContractRuleStatus) => {
  switch (rule) {
    case ContractRuleStatus.Disallowed:
      return 'component.appointmentOptionsSelect.ohc.notification.disallowed'
    case ContractRuleStatus.AssessmentRequired:
      return 'component.appointmentOptionsSelect.ohc.notification.assessmentRequired'
    case ContractRuleStatus.PaymentCommitmentAllowed:
      return 'component.appointmentOptionsSelect.ohc.notification.paymentCommitmentAllowed'
    case ContractRuleStatus.PaymentCommitmentDisallowed:
      return 'component.appointmentOptionsSelect.ohc.notification.paymentCommitmentDisallowed'
    case ContractRuleStatus.DentalRestriction:
      return 'component.appointmentOptionsSelect.ohc.notification.dentalRestriction'
    case ContractRuleStatus.CompanyInsurance:
      return 'component.appointmentOptionsSelect.ohc.notification.companyInsurance'
    default:
      return ''
  }
}

const showErrorNotification = (rule: ContractRuleStatus) => !isPaidByOHC(rule)
const showWarningNotification = (rule: ContractRuleStatus) =>
  [ContractRuleStatus.PaymentCommitmentAllowed, ContractRuleStatus.CompanyInsurance].includes(rule)
