import styled from '@emotion/styled'
import { Gray100, White } from '@mehilainen/mds-customer/colors'
import { Button } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { DentalMaintenance } from '../../../common/components/DentalMaintenance/DentalMaintenance'
import { ColumnFlex } from '../../../common/components/Layout/Layout'
import NavBar from '../../../common/components/NavBar/NavBar'
import useChangeLanguage from '../../../common/hooks/useChangeLanguage'

const Container = styled(ColumnFlex)`
  height: 100%;
  background: ${Gray100};
`

const NavContainer = styled.div`
  background: ${White};
`

const BackButton = styled(Button)`
  margin: 24px;
  align-self: center;
`

interface Props {
  onBack(): void
}

const MaintenanceView: React.VFC<Props> = ({ onBack }) => {
  const { changeLanguage } = useChangeLanguage()
  const { t } = useTranslation()

  return (
    <Container>
      <NavContainer>
        <NavBar onLanguageSelect={changeLanguage} variant="detached" />
      </NavContainer>
      <DentalMaintenance variant="full" />
      <BackButton onClick={onBack}>{t('maintenance.back')}</BackButton>
    </Container>
  )
}

export default MaintenanceView
