import styled from '@emotion/styled'
import { Primary500 } from '@mehilainen/mds-customer/colors'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { getCustomerServiceNumber } from '../../services/customerService'
import { scale } from '../../utils/scale'
import { ColumnFlex } from '../Layout/Layout'
import { Text } from '../Typography/Typography'

const Container = styled(ColumnFlex)`
  ${Text} + ${Text} {
    margin-top: ${scale(2)};
  }
`

const PractitionerBookingInstructions: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t, i18n } = useTranslation()
  const link = getCustomerServiceNumber('general', i18n.language)
  const { label: telNumber } = link
  return (
    <Container>
      <Text as="h2" $height="Medium" $size={500} $weight="Medium">
        {t('component.practitionerBookingInstructions.header')}
      </Text>
      <Text $height="Large">
        <Trans
          i18nKey={'component.practitionerBookingInstructions.description'}
          values={{ telNumber }}
        >
          <Text as="a" $height="Large" $weight="Medium" $color={Primary500} href={link.link} />
        </Trans>
      </Text>
      <Text $size={300} $height="Large">
        {t('component.practitionerBookingInstructions.footnote')}
      </Text>
    </Container>
  )
}

export default PractitionerBookingInstructions
