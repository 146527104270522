import styled from '@emotion/styled'
import { Primary500, Primary600 } from '@mehilainen/mds-customer/colors'
import { AngleBottom, Globe } from '@mehilainen/mds-customer/icons'
import { Popover } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SupportedLanguage } from '../../../__generated__/api'
import { LangOptionButtonBorderColor } from '../../../constants'
import i18n from '../../i18n/i18n'
import { scale } from '../../utils/scale'
import { CenteredColumnFlex } from '../Layout/Layout'
import { Text } from '../Typography/Typography'

const ButtonWithIcon = styled.button`
  cursor: pointer;
  background: transparent;
  color: ${Primary500};
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 4px;
`

const MenuContainer = styled(CenteredColumnFlex)`
  padding: ${scale(1.5)};
  width: 250px;
  align-items: flex-start;
`

const MenuItem = styled.div<{ dense?: boolean }>`
  display: flex;
  flex-direction: row;
  background: transparent;
  border: none;
  width: 100%;
  padding: ${(props) => (props.dense ? `${scale(1)}` : `${scale(2)} ${scale(1)}`)};
  justify-content: space-between;
`

const MenuOption = styled(MenuItem)`
  :not(:last-child) {
    border-bottom: 1px solid ${LangOptionButtonBorderColor};
  }
`

const MenuOptionButton = styled(MenuOption)`
  color: ${Primary500};

  :hover {
    color: ${Primary600};
    cursor: pointer;
  }

  a {
    line-height: 1.8;
  }
`
MenuOptionButton.defaultProps = { as: 'button' }

interface Props {
  onLanguageSelect(lang: SupportedLanguage): void
  className?: string
}

const LangSelectButton: React.FC<React.PropsWithChildren<Props>> = ({
  onLanguageSelect,
  className,
}) => {
  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault()
      setAnchorEl(event.currentTarget)
    },
    [setAnchorEl]
  )

  const languages = [
    {
      langCode: SupportedLanguage.Fi,
      label: 'Suomeksi',
    },
    {
      langCode: SupportedLanguage.Sv,
      label: 'På Svenska',
    },
    {
      langCode: SupportedLanguage.En,
      label: 'In English',
    },
  ]

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLangSelect = (selected: SupportedLanguage) => {
    handleClose()
    onLanguageSelect(selected)
  }

  return (
    <>
      <ButtonWithIcon
        onClick={handleClick}
        aria-expanded={anchorEl !== null}
        aria-owns={anchorEl ? 'language-popover' : undefined}
        aria-label={`Valitse kieli, switch language, välj språk - ${t('common.currentLanguage')}`}
        className={className}
      >
        <Globe />
        <Text $size={400} $weight="Regular">
          {i18n.language.toUpperCase()}
        </Text>
        <AngleBottom />
      </ButtonWithIcon>

      <Popover
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id="language-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorReference="anchorEl"
        disableScrollLock
        PaperProps={{
          style: {
            marginTop: scale(5),
            marginLeft: '0',
          },
        }}
      >
        <MenuContainer>
          {languages.map((language, index) =>
            i18n.language === language.langCode ? (
              <MenuOption key={`${language.langCode}-${index}`}>
                <Text $size={200} $weight="Regular" lang={language.langCode}>
                  {language.label}
                </Text>
              </MenuOption>
            ) : (
              <MenuOptionButton
                onClick={() => handleLangSelect(language.langCode)}
                key={`${language.langCode}-${index}`}
                lang={language.langCode}
              >
                <Text $size={200} $weight="Regular" as="a" className="no-external-icon">
                  {language.label}
                </Text>
                <Globe />
              </MenuOptionButton>
            )
          )}
        </MenuContainer>
      </Popover>
    </>
  )
}

export default LangSelectButton
