import styled from '@emotion/styled'
import { Gray300 } from '@mehilainen/mds-customer/colors'

import { scale } from '../../utils/scale'

const Divider = styled.div<{ $margin?: string; $marginTop?: string; $marginBottom?: string }>`
  width: 100%;
  border-bottom: 1px solid ${Gray300};
  margin-top: ${(props) => props.$marginTop ?? props.$margin ?? scale(3)};
  margin-bottom: ${(props) => props.$marginBottom ?? props.$margin ?? scale(3)};
`

export default Divider
